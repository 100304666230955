.
<template>
  <div class="vld-parent">
    <loading class="ml-20 gtBlue800" v-model:active="isLoading" :is-full-page="fullPage" />
  </div>

  <div class="min-h-screen bg-white flex flex-col">
    <!-- Top nav-->
    <MobileHeader />
    <!-- Bottom section -->
    <div class="min-h-0 flex-1 flex">
      <!-- Narrow sidebar-->
      <Nav />
      <!-- Main area -->
      <main class="min-w-0 flex-1 border-t border-gray-200 lg:flex">
        <section aria-labelledby="primary-heading" class="min-w-0 flex-1 h-full flex flex-col lg:order-last">
          <!-- Page Heading -->
          <PageHeading title="Documents" />
          <section v-if="!isLoading" class="mx-12 2xl:mx-14 sm:mx-5 md:mx-5 xl:mx-10 px-4 sm:px-6 lg:px-8">
            <div class="-my-2 pb-16 sm:-mx-6 lg:-mx-8">
              <div class="grid gap-x-2 grid-cols-6 h-10">
                <button
                  class="w-full font-bold duration-100 ease-out rounded-3xl"
                  :class="displayed === 1 ? 'bg-buttonBlue hover:bg-darkBlue focus-visible:bg-darkBlue text-trueWhite disabled:bg-gray-400 hover:border-darkBlue' : 'box-border border-2 border-solid btn disabled:border-gray-400 disabled:text-gray-400 border-buttonBlue hover:border-transparent focus-visible:border-transparent hover:bg-darkBlue focus-visible:bg-darkBlue text-buttonBlue hover:text-trueWhite focus-visible:text-trueWhite'"
                  @click="selectDocumentType(1)"
                >
                  Federal
                </button>
                <button
                  class="w-full font-bold duration-100 ease-out rounded-3xl"
                  :class="displayed === 2 ? 'bg-buttonBlue hover:bg-darkBlue focus-visible:bg-darkBlue text-trueWhite disabled:bg-gray-400 hover:border-darkBlue' : 'box-border border-2 border-solid btn disabled:border-gray-400 disabled:text-gray-400 border-buttonBlue hover:border-transparent focus-visible:border-transparent hover:bg-darkBlue focus-visible:bg-darkBlue text-buttonBlue hover:text-trueWhite focus-visible:text-trueWhite'"
                  @click="selectDocumentType(2)"
                >
                  State
                </button>
                <button
                  class="w-full font-bold duration-100 ease-out rounded-3xl"
                  :class="displayed === 3 ? 'bg-buttonBlue hover:bg-darkBlue focus-visible:bg-darkBlue text-trueWhite disabled:bg-gray-400 hover:border-darkBlue' : 'box-border border-2 border-solid btn disabled:border-gray-400 disabled:text-gray-400 border-buttonBlue hover:border-transparent focus-visible:border-transparent hover:bg-darkBlue focus-visible:bg-darkBlue text-buttonBlue hover:text-trueWhite focus-visible:text-trueWhite'"
                  @click="selectDocumentType(3)"
                >
                  Program
                </button>
                <button
                  class="w-full font-bold duration-100 ease-out rounded-3xl"
                  :class="displayed === 'archive' ? 'bg-buttonBlue hover:bg-darkBlue focus-visible:bg-darkBlue text-trueWhite disabled:bg-gray-400 hover:border-darkBlue' : 'box-border border-2 border-solid btn disabled:border-gray-400 disabled:text-gray-400 border-buttonBlue hover:border-transparent focus-visible:border-transparent hover:bg-darkBlue focus-visible:bg-darkBlue text-buttonBlue hover:text-trueWhite focus-visible:text-trueWhite'"
                  @click="selectDocumentType('archive')"
                >
                  Archived
                </button>
              </div>

              <div class="rounded-lg align-middle inline-block surrounding-shadow mt-4 w-full overflow-auto">
                <div class="border-b border-gray-200 sm:rounded-lg w-max lg:w-auto">
                    <div class="flex">
                    <nav class="bg-white px-2 py-3 rounded-t-lg flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
                      <div class="hidden sm:block -ml-4 lg:-ml-3">
                        <span class="inline-flex items-center cursor-pointer py-1.5 px-2.5 rounded-3xl ease-out duration-100 bg-buttonBlue hover:bg-darkBlue text-trueWhite">
                          <button type="button" class="gt-btn">
                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                            </svg>
                          </button>
                          <a href="documents/add"> New Document </a>
                        </span>
                      </div>
                      <div class="ml-5">
                        <SearchBar
                          v-model:searchQuery="searchQuery"
                          classes="w-72 border border-gray-200 rounded-md h-[40px] flex"
                        />
                      </div>
                      <div v-if="displayed !== 1" class="flex items-center ml-5">
                        <Multiselect
                          v-model="params.state"
                          placeholder="Search by State"
                          class="border border-gray-300 rounded-md"
                          @change="stateChanged"
                          :options="multiStateOptions"
                          :searchable="true"
                        />
                      </div>
                      <div v-if="displayed !== 1 && displayed !== 2" class="flex items-center ml-5">
                        <Multiselect
                          @change="agencyChanged"
                          class="border border-gray-300 rounded-md"
                          placeholder="Search by Agency"
                          v-model="params.agency"
                          :searchable="true"
                          :options="agenciesInStates"
                        ></Multiselect>
                      </div>
                    </nav>
                    <div class="bg-white flex-1 inset-y-0 right-0 flex items-center justify-end pr-2 sm:static sm:inset-auto sm:pr-0">
                      <div class="right-1 flex items-center h-full pl-4 pr-0 border-l-2 border-gray-300">
                        <label for="view" class="block text-gray-700">View</label>
                        <select
                          v-model="params.limit"
                          @change="changeDocumentsLimit($event)"
                          id="view"
                          name="view"
                          class="cursor-pointer chevron-down mt-1 block w-full px-1 pb-1 ml-1 text-gtGray900 bg-white focus:outline-none focus:ring-gtGray500 focus:border-gtGray500 rounded-md"
                        >
                          <option v-for="(option, key) in options" :value="option" :key="key">
                            {{ option }}
                          </option>
                        </select>
                        <svg class="h-6 w-10 -ml-2 mb-1 text-gtGrayDownIcon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <div class="table-container-1 w-auto lg:w-full">
                    <div class="table-container-2">
                      <div class="fixed flex justify-center my-3 left-1/2 top-1/3 text-gray-500" v-if="showSpinner">
                        <svg class="animate-spin -ml-1 mr-3 h-8 w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                      </div>
                      <table class="min-w-full divide-y divide-gray-200 table-auto" :class="showSpinner ? 'opacity-25' : ''">
                        <thead class="bg-gtBlue50">
                          <tr>
                            <th scope="col" class="px-6 py-3 text-left text-gray-500 tracking-wider">
                              <a href="javascript:void(0)" class="flex items-center" @click.prevent="setSorting('title')">
                                Document Name
                                <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" v-if="params.order == 'desc'">
                                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                                </svg>
                                <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" v-if="params.order == 'asc'">
                                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                                </svg>
                              </a>
                            </th>
                            <th v-if="displayed === 'archive'" scope="col" class="px-6 py-3 text-center text-gray-500 tracking-wider">Type</th>
                            <th v-if="displayed === 3" scope="col" class="px-6 py-3 text-center text-gray-500 tracking-wider">Agency</th>
                            <th v-if="displayed !== 1" scope="col" class="px-6 py-3 text-center text-gray-500 tracking-wider">State</th>
                            <th scope="col" class="px-6 py-3 text-center text-gray-500 tracking-wider">Last Updated</th>
                            <th scope="col" class="px-6 py-3 text-center text-gray-500 tracking-wider">Associated Packets</th>

                            <th v-if="showKebabMenu" scope="col" class="relative px-6 py-3">
                              <span class="sr-only">Edit</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                          <tr class="hover:bg-gtBlue cursor-pointer" v-for="(doc, index) in documentType" :key="index" @click="navigateToEditDocument(doc?.id)">
                            <td class="px-5 py-2 whitespace-nowrap text-gray-900">
                              {{ doc?.displayText || doc?.title }}
                            </td>
                            <td v-if="displayed === 'archive'" class="px-5 py-2 whitespace-nowrap text-center text-gray-500">
                              {{ doc?.documentType }}
                            </td>
                            <td v-if="displayed === 3" :title="doc?.agencies?.map(a=>a.name).toString()" class="px-5 py-2 whitespace-nowrap text-center text-gray-500">
                              {{ getFormattedAgency(doc) || 'All Agencies' }}
                            </td>
                            <td v-if="displayed !== 1" class="px-5 py-2 whitespace-nowrap text-center text-gray-500">
                              {{ doc?.state?.name || 'All States' }}
                            </td>
                            <td class="px-5 py-2 whitespace-nowrap text-center text-gray-500">
                              {{ getFormattedDate(doc?.updatedAt, 'L')}}
                            </td>
                            <td class="px-5 py-2 whitespace-nowrap text-center text-gray-500">
                              {{ doc?.associatedPackets?.length || 0 }}
                            </td>
                            <td v-if="showKebabMenu" class="px-5 py-2 whitespace-nowrap text-right" @click.stop>
                              <dropdown classes="dropdown-right">
                                <template v-slot:trigger>
                                  <button class="text-gray-500 hover:text-gray-900">
                                    <svg class="h-7 w-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                                      />
                                    </svg>
                                  </button>
                                </template>
                                <li>
                                  <router-link :to="{ name: 'EditDocument', params: { id: doc?.id } }">
                                    <span class="py-0.5 pl-6 pr-8 text-center leading-loose block text-white hover:bg-gray-200 hover:text-gray-900"> Edit </span>
                                  </router-link>
                                </li>
                                <li>
                                  <!--  @click="archiveDoc(doc.id)"-->
                                  <a
                                    href="javascript:void(0)"
                                    @click="showArchiveDocModal(doc?.id)"
                                    class="py-0.5 pl-6 pr-8 text-center leading-loose block text-white hover:bg-gray-200 hover:text-gray-900"
                                    >Archive</a
                                  >
                                </li>
                              </dropdown>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-if="!documents.length" class="bg-white divide-y divide-gray-200">
                          <tr>
                            <td></td>
                            <td></td>
                            <td class="pl-8 py-4 whitespace-nowrap text-gray-400">No Record Found</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Pagination -->
              <Pagination
                :currentPage="paginationMeta.current_page"
                :lastPage="paginationMeta.last_page"
                @show-previous="showPreviousPage()"
                @show-next="showNextPage()"
                @show-paginated="showPaginated"
              ></Pagination>
            </div>
            <div v-if="showDocArchiveModal" class="doc-modal">
              <ArchiveDocModal :docid="documentId" @archiveDocument="archiveDoc" @dismissArchiveModal="dismissArchiveModal" @closeModalFromBackDrop="dismissArchiveModal" />
            </div>
          </section>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import documentService from 'Services/backend/documents';
import { getFormattedDate } from 'Utilities/helper';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import ArchiveDocModal from '../Modals/ArchiveDocModal.vue';
import stateService from 'Services/backend/state';
import agencyService from 'Services/backend/agency';
import sortService from 'Services/sortService'
import Multiselect from '@vueform/multiselect';

export default {
  name: 'Documents',
  data() {
    return {
      searchQuery : '',
      isOpen: false,
      fullPage: true,
      docData: [],
      docType: 'Active',
      documents: [],
      isLoading: false,
      current_page: 1,
      last_page: 1,
      paginationMeta: [],
      displayed: 1,
      showSpinner: false,
      showKebabMenu: true,
      showDocArchiveModal: false,
      documentId: '',
      params: {
        query: '',
        page: 1,
        type: 1,
        state: '',
        agency: '',
        sortBy: 'created_at',
        order: 'desc',
        isArchived: false,
        limit: '30',
      },
      options: [10, 20, 30, 40, 50],
      agencyOption: [],
      multiOptions: [
        {
          value: -1,
          label: "All Agencies",
          stateAbbrev: null,
        }
      ],
      multiStateOptions: [
        {
          value: -1,
          label: "All States",
        }
      ],
    };
  },
  components: {
    Loading,
    ArchiveDocModal,
    Multiselect,
  },
  computed: {
    documentType() {
      return this.documents.map((document) => {

          let documentType = '';

          switch (document.entityType) {
            case 1:
              documentType = 'Federal';
              break;
            case 2:
              documentType = 'State';
              break;
            case 3:
              documentType = 'Program';
              break;
            case 4:
              documentType = 'Image Capture';
              break;
            case 5:
              documentType = 'Miscellaneous';
              break;
            default:
              break;
          }
        return {...document, documentType};
        });
    },
    agenciesInStates() {
      if (this.stateAbbrev == '' || this.stateAbbrev == null) {
        return this.multiOptions.map((e) => {
          return {
            ...e,
            selected: this.agencyOption.find((x) => x === e.value),
          };
        });
      } else {
        return this.multiOptions
            .filter((agency) => {
              return agency.stateAbbrev == this.stateAbbrev;
            })
            .map((e) => {
              return {
                ...e,
                selected: this.agencyOption.find((x) => x === e.value),
              };
            });
      }
    },
  },
  watch: { 
    searchQuery(val) {
      this.searchKeyword(val);
    }
  },  
  mounted() {
    this.getAgencies();
    this.getStates();
    this.getDocuments();
    document.addEventListener('click', this.close);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.close);
  },

  methods: {
    getFormattedDate,
    getFormattedAgency(document) {
      if (document.agencies?.length > 1) return `${document.agencies[0]?.name}...${document.agencies.length - 1} more`;
      else return document.agencies?.[0]?.name;
    },
    stateChanged(e) {
      if (e == null) {
        this.stateAbbrev = '';
        this.showSpinner = true;
        this.params = {
          ...this.params,
          agency: '',
          state: '',
        };
      } else {
        this.stateAbbrev = e;
        this.showSpinner = true;
        this.params = {
          ...this.params,
          agency: '',
          state: this.stateAbbrev,
        };
      }
      this.getDocuments();

    },

    agencyChanged(e) {
      if (e == null) {
        this.stateAbbrev = '';
        this.showSpinner = true;
        this.params = {
          ...this.params,
          agency: '',
        };
      } else {
        this.showSpinner = true;
        this.params = {
          ...this.params,
          agency: e,
        };
      }
      this.getDocuments();
    },
    getStates() {
      stateService.getStates().then(
        (response) => {
          this.states = sortService.sortAlphabeticalByKey(response.data.states, 'name');
          this.states.forEach((state) => {
            const dd = {
              value: state.abbrev,
              label: state.name,
            };
            this.multiStateOptions.push(dd);
          });
        },
        (error) => {
          console.error(error);
        },
      );
    },
    getAgencies() {
      agencyService.getAgencies().then(
        (response) => {
          this.agencies = sortService.sortAlphabeticalByKey(response.data, 'name');
          this.agencies.forEach((agency) => {
            const dd = {
              value: agency.id,
              label: agency.name,
              stateAbbrev: agency.stateAbbrev,
              image: require('@/assets/tick.svg'),
            };
            this.multiOptions.push(dd);
          });
        },
        (error) => {
          console.error(error);
        },
      );
    },
    getDocuments() {
      if (!this.showSpinner) this.isLoading = true;
      documentService.getDocuments(this.params).then(
        (res) => {
          this.documents = res.data.data;
          this.isLoading = false;
          this.showSpinner = false;
          this.paginationMeta = res.data.meta;
          if (this.showAlertForArchiveDocument) {
            this.$toast(true, 'Document archived successfully!', 'This document will no longer display here.')
              .then(() => this.showAlertForArchiveDocument = false);
          }
        },
        (error) => {
          console.error(error);
          this.isLoading = false;
          this.showSpinner = false;
        },
      );
    },
    searchKeyword(query) {
      this.showSpinner = true;
      (this.params['query'] = query), (this.params['page'] = 1);
      this.getDocuments();
    },
    showPaginated(page) {
      this.paginationMeta.current_page = page;
      this.params['page'] = page;
      if (this.paginationMeta.current_page <= this.paginationMeta.last_page) {
        this.showSpinner = true;
        this.getDocuments();
      }
    },
    showPreviousPage() {
      this.params['page'] = this.paginationMeta.current_page - 1;
      this.showSpinner = true;
      this.getDocuments();
    },
    showNextPage() {
      this.params['page'] = this.paginationMeta.current_page + 1;
      this.showSpinner = true;
      this.getDocuments();
    },
    selectDocumentType(type) {
      this.showSpinner = true;

      this.showKebabMenu = true;
      this.params.isArchived = false;
      this.params.type = '';

      switch(type) {
        case 1: 
          this.params.type = 1;
          this.stateChanged();
          break;
        case 2:
          this.params.type = 2;
          this.agencyChanged();
          break;
        case 3:
          this.params.type = 3;
          break;
        case 'archive':
          this.params.isArchived = true;
          this.showKebabMenu = false;
          break;
        default: break;
      }

      this.displayed = type;
      this.getDocuments();
    },
    setSorting(fieldName) {
      this.params = {
        ...this.params,
        sortBy: fieldName,
        order: this.params.order == 'asc' ? 'desc' : 'asc',
      };
      this.showSpinner = true;
      this.getDocuments();
    },
    changeDocumentsLimit(event) {
      this.params['limit'] = event.target.value;
      this.params['page'] = 1;
      this.showSpinner = true;
      this.getDocuments();
    },

    navigateToEditDocument(id) {
      this.$router.push({ name: 'EditDocument', params: { id: id } });
    },
    showArchiveDocModal(id) {
      this.documentId = id;
      this.showDocArchiveModal = true;
    },

    archiveDoc(event) {
      this.showSpinner = true;
      documentService.archiveDocument(event).then(
        () => {
          this.showAlertForArchiveDocument = true;
          this.getDocuments();
          this.showSpinner = false;
        },
        (error) => {
          this.showSpinner = false;
          console.error(error);
        },
      );
    },

    dismissArchiveModal() {
      this.showDocArchiveModal = false;
    },
  },
};
</script>

<style scoped>
.table-container-1 {
  display: table;
  table-layout: fixed;
}

.table-container-2 {
  display: table-cell;
}
</style>
