.
<template>
  <div class="w-auto h-auto mt-4">
    <img class="mx-4 w-11/12 h-1/3 rounded-2xl" :src="imgUrl">
  </div>
</template>

<script>
export default {
  name: 'Welcome1Content',
  data() {
    return {
      imgUrl: require('@/assets/images/welcome-preview.jpg'),
      videoIconUrlrequire: require('@/assets/videos/GT_Enroll_intro_video.mp4') + "#t=0.6",
    };
  },
};
</script>

<style></style>
