<template>
  <!-- Participant Packet -->
  <template v-if="choice == 'participant'">
    <div class="surrounding-shadow overflow-hidden border-b border-gray-200 sm:rounded-lg my-4">
      <div class="bg-white px-4 py-3 border-b border-gray-200 sm:px-6 flex justify-between items-center">
        <h3 class="leading-6 text-darkBlue">{{ tableName }} ({{ documentRowCount }})</h3>
        <button
          @click="showDocumentModal"
          class="flex flex-row items-center py-1.5 px-2.5 rounded-3xl ease-out duration-100 bg-buttonBlue hover:bg-darkBlue text-trueWhite"
        >
          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
          Add Document
        </button>
      </div>
      <table class="min-w-full divide-y divide-gray-200 w-100">
        <thead class="bg-gtBlue50">
          <tr>
            <th scope="col" class="px-6 py-3 text-left text-gray-500 uppercase tracking-wider w-45">Name</th>
            <th scope="col" class="px-6 py-3 text-left text-gray-500 uppercase tracking-wider w-45">Last Update</th>
            <th scope="col" class="px-6 py-3 w-10">
              <span class="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <!-- federalDocuments -->
        <template v-if="participantDocuments?.federalDocuments">
          <draggable
            v-model="participantData.federalDocuments"
            @end="updateDocumentOrder(participantData.federalDocuments)"
            tag="tbody"
            item-key="id"
            class="bg-white divide-y divide-gray-200 text-gray-800"
          >
            <template #item="{ element }">
              <tr v-if="element.entityType == entityType">
                <td scope="row" class="px-6 py-4 whitespace-wrap text-gray-600">
                  {{ element.displayText || element.title }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-gray-500">
                  {{ element.updatedAt == null ? ' ' : getFormattedDate(element.updatedAt, 'l') }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-gray-500">
                  <a @click="deleteDocumentFromPacket(element.packetDocumentId)" title="Remove Document" href="javascript:void(0)" class="text-gray-500 hover:text-gray-900">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </a>
                </td>
              </tr>
            </template>
          </draggable>
        </template>
        <!-- stateDocuments -->
        <template v-if="participantDocuments?.stateDocuments">
          <draggable
            v-model="participantData.stateDocuments"
            @end="updateDocumentOrder(participantData.stateDocuments)"
            tag="tbody"
            item-key="id"
            class="bg-white divide-y divide-gray-200 text-gray-800"
          >
            <template #item="{ element }">
              <tr v-if="element.entityType == entityType">
                <td scope="row" class="px-6 py-4 whitespace-wrap text-gray-600">
                  {{ element.displayText || element.title }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-gray-500">
                  {{ element.updatedAt == null ? ' ' : getFormattedDate(element.updatedAt, 'l') }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-gray-500">
                  <a @click="deleteDocumentFromPacket(element.packetDocumentId)" title="Remove Document" href="javascript:void(0)" class="text-gray-500 hover:text-gray-900">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </a>
                </td>
              </tr>
            </template>
          </draggable>
        </template>
        <!-- programDocuments -->
        <template v-if="participantDocuments?.programDocuments">
          <draggable
            v-model="participantData.programDocuments"
            @end="updateDocumentOrder(participantData.programDocuments)"
            tag="tbody"
            item-key="id"
            class="bg-white divide-y divide-gray-200 text-gray-800"
          >
            <template #item="{ element }">
              <tr v-if="element.entityType == entityType">
                <td scope="row" class="px-6 py-4 whitespace-wrap text-gray-600">
                  {{ element.displayText || element.title }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-gray-500">
                  {{ element.updatedAt == null ? ' ' : getFormattedDate(element.updatedAt, 'l') }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-gray-500">
                  <a @click="deleteDocumentFromPacket(element.packetDocumentId)" title="Remove Document" href="javascript:void(0)" class="text-gray-500 hover:text-gray-900">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </a>
                </td>
              </tr>
            </template>
          </draggable>
        </template>
        <!-- no documents found -->
        <template v-if="documentRowCount == 0">
          <tr class="bg-white divide-y divide-gray-200 text-gray-800">
            <td class="px-6 py-4 whitespace-nowrap text-gray-500"></td>
            <td class="px-6 py-4 whitespace-nowrap text-gray-500">Add documents to get started</td>
            <td class="px-6 py-4 whitespace-nowrap text-gray-500"></td>
          </tr>
        </template>
      </table>
    </div>
    <div v-if="showAddDocumentModal" class="doc-modal">
      <AddDocumentModal
        :personType="persontype"
        :packetid="packetId"
        :entitytype="entityType"
        :packetStateAbbrev="packetStateAbbrev"
        @clearDoc="resetDocumentDetails"
        @dismissDocModal="dismissModal"
        @assignDocumentsToPacket="connectDocumentWithPacket"
      />
    </div>
  </template>

  <!-- Caregiver Packet -->
  <template v-if="choice == 'caregiver'">
    <div class="surrounding-shadow overflow-hidden border-b border-gray-200 sm:rounded-lg my-4">
      <div class="bg-white px-4 py-3 border-b border-gray-200 sm:px-6 flex justify-between items-center">
        <h3 class="leading-6 text-darkBlue">{{ tableName }} ({{ documentRowCount }})</h3>
        <button
          @click="showDocumentModal"
          class="flex flex-row items-center py-1.5 px-2.5 rounded-3xl ease-out duration-100 bg-buttonBlue hover:bg-darkBlue text-trueWhite"
        >
          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
          Add Document
        </button>
      </div>
      <table class="min-w-full divide-y divide-gray-200 w-100">
        <thead class="bg-gtBlue50">
          <tr>
            <th scope="col" class="px-6 py-3 text-left text-gray-500 uppercase tracking-wider w-45">Name</th>
            <th scope="col" class="px-6 py-3 text-left text-gray-500 uppercase tracking-wider w-45">Last Update</th>
            <th scope="col" class="px-6 py-3 w-10">
              <span class="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <!-- federalDocuments -->
        <template v-if="caregiverDocuments?.federalDocuments">
          <draggable
            v-model="caregiverData.federalDocuments"
            @end="updateDocumentOrder(caregiverData.federalDocuments)"
            tag="tbody"
            item-key="id"
            class="bg-white divide-y divide-gray-200 text-gray-800"
          >
            <template #item="{ element }">
              <tr v-if="element.entityType == entityType">
                <td scope="row" class="px-6 py-4 whitespace-wrap text-gray-600">
                  {{ element.displayText || element.title }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-gray-500">
                  {{ element.updatedAt == null ? ' ' : getFormattedDate(element.updatedAt, 'l') }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-gray-500">
                  <a @click="deleteDocumentFromPacket(element.packetDocumentId)" title="Remove Document" href="javascript:void(0)" class="text-gray-500 hover:text-gray-900">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </a>
                </td>
              </tr>
            </template>
          </draggable>
        </template>
        <!-- stateDocuments -->
        <template v-if="caregiverDocuments?.stateDocuments">
          <draggable v-model="caregiverData.stateDocuments" @end="updateDocumentOrder(caregiverData.stateDocuments)" tag="tbody" item-key="id" class="bg-white divide-y divide-gray-200 text-gray-800">
            <template #item="{ element }">
              <tr v-if="element.entityType == entityType">
                <td scope="row" class="px-6 py-4 whitespace-wrap text-gray-600">
                  {{ element.displayText || element.title }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-gray-500">
                  {{ element.updatedAt == null ? ' ' : getFormattedDate(element.updatedAt, 'l') }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-gray-500">
                  <a @click="deleteDocumentFromPacket(element.packetDocumentId)" title="Remove Document" href="javascript:void(0)" class="text-gray-500 hover:text-gray-900">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </a>
                </td>
              </tr>
            </template>
          </draggable>
        </template>
        <!-- programDocuments -->
        <template v-if="caregiverDocuments?.programDocuments">
          <draggable
            v-model="caregiverData.programDocuments"
            @end="updateDocumentOrder(caregiverData.programDocuments)"
            tag="tbody"
            item-key="id"
            class="bg-white divide-y divide-gray-200 text-gray-800"
          >
            <template #item="{ element }">
              <tr v-if="element.entityType == entityType">
                <td scope="row" class="px-6 py-4 whitespace-wrap text-gray-600">
                  {{ element.displayText || element.title }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-gray-500">
                  {{ element.updatedAt == null ? ' ' : getFormattedDate(element.updatedAt, 'l') }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-gray-500">
                  <a @click="deleteDocumentFromPacket(element.packetDocumentId)" title="Remove Document" href="javascript:void(0)" class="text-gray-500 hover:text-gray-900">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </a>
                </td>
              </tr>
            </template>
          </draggable>
        </template>
        <!-- no documents found -->
        <template v-if="documentRowCount == 0">
          <tr class="bg-white divide-y divide-gray-200 text-gray-800">
            <td class="px-6 py-4 whitespace-nowrap text-gray-500"></td>
            <td class="px-6 py-4 whitespace-nowrap text-gray-500">Add documents to get started</td>
            <td class="px-6 py-4 whitespace-nowrap text-gray-500"></td>
          </tr>
        </template>
      </table>
    </div>
    <div v-if="showAddDocumentModal" class="doc-modal">
      <AddDocumentModal
        :personType="persontype"
        :packetid="packetId"
        :entitytype="entityType"
        :packetStateAbbrev="packetStateAbbrev"
        @clearDoc="resetDocumentDetails"
        @dismissDocModal="dismissModal"
        @assignDocumentsToPacket="connectDocumentWithPacket"
      />
    </div>
  </template>

  <!-- Vendor Packet -->
  <template v-if="choice == 'vendor'">
    <div class="surrounding-shadow overflow-hidden border-b border-gray-200 sm:rounded-lg my-4">
      <div class="bg-white px-4 py-3 border-b border-gray-200 sm:px-6 flex justify-between items-center">
        <h3 class="leading-6 text-darkBlue">{{ tableName }} ({{ documentRowCount }})</h3>
        <button
          @click="showDocumentModal"
          class="flex flex-row items-center py-1.5 px-2.5 rounded-3xl ease-out duration-100 bg-buttonBlue hover:bg-darkBlue text-trueWhite"
        >
          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
          Add Document
        </button>
      </div>
      <table class="min-w-full divide-y divide-gray-200 w-100">
        <thead class="bg-gtBlue50">
          <tr>
            <th scope="col" class="px-6 py-3 text-left text-gray-500 uppercase tracking-wider w-45">Name</th>
            <th scope="col" class="px-6 py-3 text-left text-gray-500 uppercase tracking-wider w-45">Last Update</th>
            <th scope="col" class="px-6 py-3 w-10">
              <span class="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <!-- federalDocuments -->
        <template v-if="vendorDocuments?.federalDocuments">
          <draggable
            v-model="vendorData.federalDocuments"
            @end="updateDocumentOrder(vendorData.federalDocuments)"
            tag="tbody"
            item-key="id"
            class="bg-white divide-y divide-gray-200 text-gray-800"
          >
            <template #item="{ element }">
              <tr v-if="element.entityType == entityType">
                <td scope="row" class="px-6 py-4 whitespace-wrap text-gray-600">
                  {{ element.displayText || element.title }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-gray-500">
                  {{ element.updatedAt == null ? ' ' : getFormattedDate(element.updatedAt, 'l') }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-gray-500">
                  <a @click="deleteDocumentFromPacket(element.packetDocumentId)" title="Remove Document" href="javascript:void(0)" class="text-gray-500 hover:text-gray-900">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </a>
                </td>
              </tr>
            </template>
          </draggable>
        </template>
        <!-- stateDocuments -->
        <template v-if="vendorDocuments?.stateDocuments">
          <draggable v-model="vendorData.stateDocuments" @end="updateDocumentOrder(vendorData.stateDocuments)" tag="tbody" item-key="id" class="bg-white divide-y divide-gray-200 text-gray-800">
            <template #item="{ element }">
              <tr v-if="element.entityType == entityType">
                <td scope="row" class="px-6 py-4 whitespace-wrap text-gray-600">
                  {{ element.displayText || element.title }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-gray-500">
                  {{ element.updatedAt == null ? ' ' : getFormattedDate(element.updatedAt, 'l') }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-gray-500">
                  <a @click="deleteDocumentFromPacket(element.packetDocumentId)" title="Remove Document" href="javascript:void(0)" class="text-gray-500 hover:text-gray-900">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </a>
                </td>
              </tr>
            </template>
          </draggable>
        </template>
        <!-- programDocuments -->
        <template v-if="vendorDocuments?.programDocuments">
          <draggable
            v-model="vendorData.programDocuments"
            @end="updateDocumentOrder(vendorData.programDocuments)"
            tag="tbody"
            item-key="id"
            class="bg-white divide-y divide-gray-200 text-gray-800"
          >
            <template #item="{ element }">
              <tr v-if="element.entityType == entityType">
                <td scope="row" class="px-6 py-4 whitespace-wrap text-gray-600">
                  {{ element.displayText || element.title }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-gray-500">
                  {{ element.updatedAt == null ? ' ' : getFormattedDate(element.updatedAt, 'l') }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-gray-500">
                  <a @click="deleteDocumentFromPacket(element.packetDocumentId)" title="Remove Document" href="javascript:void(0)" class="text-gray-500 hover:text-gray-900">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </a>
                </td>
              </tr>
            </template>
          </draggable>
        </template>
        <!-- no documents found -->
        <template v-if="documentRowCount == 0">
          <tr class="bg-white divide-y divide-gray-200 text-gray-800">
            <td class="px-6 py-4 whitespace-nowrap text-gray-500"></td>
            <td class="px-6 py-4 whitespace-nowrap text-gray-500">Add documents to get started</td>
            <td class="px-6 py-4 whitespace-nowrap text-gray-500"></td>
          </tr>
        </template>
      </table>
    </div>
    <div v-if="showAddDocumentModal" class="doc-modal">
      <AddDocumentModal
        :personType="persontype"
        :packetid="packetId"
        :entitytype="entityType"
        :packetStateAbbrev="packetStateAbbrev"
        @clearDoc="resetDocumentDetails"
        @dismissDocModal="dismissModal"
        @assignDocumentsToPacket="connectDocumentWithPacket"
      />
    </div>
  </template>

  <!-- Remove Document Modal -->
  <div v-if="showRemoveDocumentModal">
    <RemoveDocumentModal
      :id="removeDocumentId"
      @removePacket="removeDocument"
      @dismissPacketAgencyModal="dismissModal"
      modalTitle="Remove Document!"
      modalText="Are you sure you want to remove the document?"
    />
  </div>
</template>

<script>
import AddDocumentModal from 'Views/Packets/SharedPacketComponents/Modals/AddDocumentModal';
import { getFormattedDate } from 'Utilities/helper';
import draggable from 'vuedraggable';
import packetService from 'Services/backend/packets';
import RemoveDocumentModal from 'Views/Packets/SharedPacketComponents/Modals/RemoveConfirmationModal';

export default {
  emits: ['getpacket'],
  created() {
    this.participantData = this.participantDocuments;
    this.caregiverData = this.caregiverDocuments;
    this.vendorData = this.vendorDocuments;
    this.packet.id = this.$route.params.id;
    this.documentRowCount = this.tableRowCount;
  },
  mounted() {
    this.persontype = this.personType;
    this.packetStateAbbrev = this.packetState;
  },
  data() {
    return {
      removeDocumentId: '',
      showAddDocumentModal: false,
      dragging: false,
      showRemoveDocumentModal: false,
      documentRowCount: '',
      participantData: [],
      caregiverData: [],
      vendorData: [],
      persontype: '',
      packetStateAbbrev: '',
      packet: {
        id: '',
      },
      body: {
        documentId: [],
      },
    };
  },
  components: {
    AddDocumentModal,
    draggable,
    RemoveDocumentModal,
  },
  props: ['tableName', 'tableRowCount', 'participantDocuments', 'caregiverDocuments', 'vendorDocuments', 'entityType', 'choice', 'packetId', 'personType', 'packetState'],
  methods: {
    getFormattedDate,
    getPacket() {
      packetService.getPacket(this.packet.id).then(
        (response) => {
          this.packet = response.data.data;
          this.caregiverData = this.packet.caregiverDocuments;
          this.participantData = this.packet.participantDocuments;
          this.vendorData = this.packet.vendorDocuments;
        },
        (error) => {
          console.error(error.response);
        },
      );
    },
    showDocumentModal() {
      this.showAddDocumentModal = true;
    },
    dismissModal() {
      this.showAddDocumentModal = false;
      this.showRemoveDocumentModal = false;
    },
    deleteDocumentFromPacket(id) {
      this.showRemoveDocumentModal = true;
      this.removeDocumentId = id;
    },
    removeDocument(id) {
      packetService.deleteDocumentFromPacket(id).then(
        () => {
          this.getPacket();
          this.documentRowCount--;
          this.$toast(true, 'Document removed successfully!', 'This document will no longer display here.');
        },
        (error) => {
          console.error(error);
        },
      );
    },
    resetDocumentDetails() {
      this.showAddDocumentModal = false;
    },
    updateDocumentOrder(packetId) {
      const body = {
        packetDocuments: [],
      };
      packetId.map((packet) => {
        body.packetDocuments.push({
          packetDocumentId: packet.packetDocumentId,
        });
      });
      packetService.updateDocumentOrder(body).then((error) => {
        console.error(error);
      });
    },
    connectDocumentWithPacket(documentId) {
      this.body.documentId = documentId;
      packetService.assignDocumentToPacket(this.packetId, this.body).then(
        () => {
          this.$emit('getpacket');
        },
        (error) => {
          console.error(error);
        },
      );
      this.showAddDocumentModal = false;
    },
  },
};
</script>

<style scoped>
tr:hover {
  cursor: move;
  background: rgb(233, 237, 240);
}
</style>
