<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="fixed z-50 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:min-h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg pt-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
        <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
          <button @click="dismissDocModal" type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="sr-only">Close</span>
            <!-- Heroicon name: outline/x -->
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-xl leading-6 text-darkBlue" id="modal-title">Add {{ getDocumentType }} Documents</h3>
          </div>
        </div>
        <div>
          <div class="pt-0 pb-3 px-5 sm:mt-6 flex items-center">
            <nav class="h-full w-full overflow-y-auto overflow-x-hidden border border-gtGray300 rounded-lg p-4" aria-label="Directory">
              <div class="flex" :class="showSpinner ? 'opacity-25' : ''">
                <div class="relative w-7/12 mx-1">
                  <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                    <svg class="shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                    </svg>
                  </div>
                  <input
                    @input="searchKeyword($event)"
                    name="search"
                    class="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-gray-900 focus:border-gray-900"
                    placeholder="Search"
                    type="search"
                  />
                </div>
                <div class="relative w-5/12 mx-1">
                    <select
                      v-model="params.agency"
                      @change="agencyChanged"
                      class="min-w-full border text-gray-500 border-gray-300 h-9 sm:px-1 bg-white shadow-sm block w-full rounded-md"
                    >
                      <option value="">Search by Agency</option>
                      <option :value="agency.id" v-for="agency in agencies" :key="agency.id">
                        {{ agency.name }}
                      </option>
                    </select>
                </div>
              </div>
              <div class="flex flex-col relative mt-2 h-96">
                <table class="min-w-full divide-y divide-gray-200">
                  <tbody class="bg-white divide-y divide-gray-200">
                    <div class="fixed flex justify-center my-3 left-1/2 top-1/3 text-gray-500" v-if="showSpinner">
                      <svg class="animate-spin -ml-1 mr-3 h-8 w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </div>
                    <template v-if="getFilterDocuments.length">
                      <tr>
                        <th class="px-2 py-4 whitespace-nowrap text-gray-800 cursor-pointer font-bold">Name</th>
                        <th class="px-2 py-4 whitespace-nowrap text-gray-800 cursor-pointer font-bold">Signers</th>
                        <th v-if="entityType === 2" class="px-2 py-4 whitespace-nowrap text-gray-800 cursor-pointer font-bold">State</th>
                        <th class="px-2 py-4 whitespace-nowrap text-gray-800 cursor-pointer font-bold">ID</th>
                      </tr>
                      <tr v-for="document in getFilterDocuments" :key="document.id">
                        <td
                          @click="selectDocument(document)"
                          class="px-2 py-4 whitespace-wrap flex items-center cursor-pointer"
                          :class="document.docRowClicked ? 'text-buttonBlue' : 'text-gtGray800'"
                        >
                          {{ document?.displayText || document?.title }}
                          <svg v-if="document.docRowClicked" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-2 text-buttonBlue" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                          </svg>
                        </td>
                        <td class="px-2 py-4 whitespace-wrap text-gray-800 cursor-pointer">
                          {{ convertSignerList(document?.additionalSignatureTypes) }}
                        </td>
                        <td v-if="entityType === 2" class="px-2 py-4 whitespace-nowrap text-gray-800 cursor-pointer">
                          {{ document?.state?.name }}
                        </td>
                        <td class="px-2 py-4 whitespace-nowrap text-gray-800 cursor-pointer">
                          {{ document?.id }}
                        </td>
                        <!-- Hide Preview column for Phase 1 -->
                        <!-- <td class="px-2 py-4 whitespace-nowrap text-gtBlue500 flex items-center justify-end cursor-pointer">
                          Preview
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z"
                            />
                          </svg>
                        </td> -->
                      </tr>
                    </template>
                    <template v-else-if="!getFilterDocuments.length && !showSpinner">
                      <tr>
                        <td></td>
                        <td class="px-2 py-4 whitespace-nowrap text-gray-500 flex items-center justify-center cursor-pointer">No Documents Found</td>
                        <td></td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </nav>
          </div>
          <div class="mt-1 py-4 w-full sm:mt-4 flex items-center justify-end bg-gtBlue100">
            <button
              @click="dismissDocModal"
              type="button"
              class="w-28 inline-flex justify-center px-2 py-2 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite focus:outline-none sm:col-start-2"
            >
              Cancel
            </button>
            <button
              @click="assignDocuments"
              type="button"
              class="mt-3 mx-4 w-40 inline-flex justify-center border-2 border-transparent px-4 py-2 rounded-3xl ease-out duration-100 bg-buttonBlue hover:bg-darkBlue text-trueWhite focus:outline-none sm:mt-0 sm:col-start-1"
            >
              Add Documents
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import agencyService from 'Services/backend/agency';
import documentService from 'Services/backend/documents';

export default {
  data() {
    return {
      isExistingDocument: false,
      showSpinner: false,
      states: [],
      agencies: [],
      documents: [],
      sortDocumentType: [],
      documentId: [],
      docRowClicked: false,
      agencyId: '',
      persontype: '',
      documentPersonType: '',
      params: {
        query: '',
        state: '',
        agency: '',
        page: 1,
        sortBy: 'created_at',
        order: 'desc',
        isArchived: false,
        limit: 'all',
      },
      entityType: '',
    };
  },
  props: ['entitytype', 'packetid', 'personType', 'packetStateAbbrev'],
  created() {
    this.entityType = this.entitytype;
    this.getDocuments();
    this.getAgencies();
    if (this.personType == '') {
      this.persontype = 1;
    } else {
      this.persontype = this.personType;
    }
  },
  methods: {
    dismissDocModal() {
      this.$emit('dismissDocModal');
    },
    closeModal() {
      this.$emit('closeModalFromBackDrop');
    },
    toggleSelectDocument() {
      this.isExistingDocument = !this.isExistingDocument;
    },
    getAgencies() {
      agencyService.getAgencies().then(
        (response) => {
          this.agencies = response.data;
        },
        (error) => {
          console.error(error);
        },
      );
    },
    getDocuments() {
      this.showSpinner = true;
      documentService.getDocuments(this.params).then((response) => {
        this.documents = response.data;
        this.showSpinner = false;
      });
    },
    searchKeyword(event) {
      this.showSpinner = true;
      (this.params['query'] = event.target.value), (this.params['page'] = 1);
      this.getDocuments();
    },
    selectDocument(doc) {
      doc.docRowClicked = !doc.docRowClicked;
      if (doc.docRowClicked) {
        this.documentId.push(doc.id);
      } else {
        this.documentId.splice(this.documentId.indexOf(doc.id), 1);
      }
    },
    agencyChanged(e) {
      this.agencyId = e.target.value;
      this.showSpinner = true;
      this.params = {
        ...this.params,
        query: '',
        agency: this.agencyId,
      };
      this.getDocuments();
    },
    assignDocuments() {
      this.$emit('assignDocumentsToPacket', this.documentId);
    },
    convertSignerList(list) {
      return list.map(l => {
        switch(l) {
          case '1': return 'PT';
          case '2': return 'CG';
          case '3': return 'VEND';
          case '97': return 'EOR';
          case '98': return 'REP';
          case '99': return 'FSR'
        }
      }).sort().join(', ')
    }
  },
  computed: {
    getFilterDocuments() {
      if(this.documents)
      {
        return this.documents.filter((document) => {
          if (this.getDocumentType === 'State') {
            return document.personType == this.persontype && document.entityType == this.entityType && document.stateAbbrev == this.packetStateAbbrev;
          } else {
            return document.personType == this.persontype && document.entityType == this.entityType;
          }
        });
      }
      return []
    },
    getDocumentType() {
      let returnVal = '';

      switch (this.entityType) {
        case 1:
          returnVal = 'Federal';
          break;
        case 2:
          returnVal = 'State';
          break;
        case 3:
          returnVal = 'Program';
          break;
        case 4:
          returnVal = 'Image Capture';
          break;
        case 5:
          returnVal = 'Miscellaneous';
          break;
        default:
          break;
      }
      return returnVal;
    },
  },
};
</script>

<style>
.backdrop {
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.2);
}
</style>
