<template>
    <div class="min-h-screen w-full">
        <div id="map" class="h-screen"></div>
    </div>
</template>

<script>
import moment from 'moment';
import {ENROLLMENTS_SCHEDULE_TYPES, GOOGLE_MAP_KEY} from '@/config';
import {loadedGoogleMapsAPI} from "@/main";

export default {
    name : 'GoogleMap',
    props: {
        placemarkers: {
            type: Array,
            default: function() {
                return []
            },
        },
        zoomLevel : {
            type : Number,
            default : 12
        },
        participants : {
            type : Array,
            default: function() {
                return []
            }
        },
        centerMap: {
            type : Boolean,
            default: function() {
                return false;
            }
        },
        panTo: {
            type: Object,
            default: function() {
              return {}
            }
        }
    },
    data () {
        return {
            google_api_key : '',
            enrollment_schedule_types : []
        }
    },
    watch: {
        centerMap: {
            immediate: true,
            handler(val) {
                if(val) {
                    this.setPlacemarkers();
                }
            }
        },
        panTo: {
          immediate: true,
          handler(val) {
            if (val.lat && val.lng && window.map) {
                window.map.panTo(val);
            }
          }
        }
    },
    mounted() {
        this.enrollment_schedule_types = ENROLLMENTS_SCHEDULE_TYPES;

        loadedGoogleMapsAPI.then(() => this.initLocationSearch())
    },
    methods: {
        initLocationSearch() {
            const map = new window.google.maps.Map(document.getElementById("map"), {
                zoom: this.zoomLevel,
                mapId: 'SCHEDULE_MAP'
            });            
            window.map = map;
            // little hack to remove the position relative from the map 
            // which is present by default.
            setTimeout(() => {
                document.getElementById('map').style.position = "static";
            }, 100);

            this.setPlacemarkers();
            this.setEventListenersForMapClick(map);
        },
        setPlacemarkers() {
            const map = window.map;

            let marker;
            let svg;
            let setFirstAsCenter = true
            for(let i = 0; i < this.participants.length; i++) {
                // this is a little hack to determine the 
                // position of the map. Right now we are 
                // showing the map related to the first placemarker.
                // Note: since we choose to set the first participant as the center to start, choose the first that has a valid address.
                if(setFirstAsCenter && this.participants[i].addressPrimary) {
                    setFirstAsCenter = false
                    map.setCenter({lat: this.participants[i].addressPrimary.latitude, lng: this.participants[i].addressPrimary.longitude});                    
                }
                
                if(this.participants[i].scheduleId == this.enrollment_schedule_types.OPEN) {
                    svg = this.getSvgForNotScheduledParticipant();
                } else if(this.participants[i].scheduleId == this.enrollment_schedule_types.SCHEDULED) {
                    svg = this.getSvgForScheduledParticipant();
                }

                // hack ends here
                if (this.participants[i].addressPrimary) {
                  marker = new window.google.maps.marker.AdvancedMarkerElement({
                    position: { lat : this.participants[i].addressPrimary.latitude, lng : this.participants[i].addressPrimary.longitude},
                    map,
                    content: this.getSetIconObject(svg),
                    gmpClickable: true
                  });

                  const message = this.getMessageDetails(this.participants[i]);

                  this.attachSecretMessage(marker, message, this.participants[i]);

                  setTimeout(() => {
                    marker.setMap(map);
                  }, 500);
                }
            }
        },
        getMessageDetails(participant) {
            if(participant.scheduleId && participant.scheduleId != this.enrollment_schedule_types.OPEN && participant.appointments.length) {
                const appointment = participant.appointments[participant.appointments.length - 1];
              return `<div>` +
                    `<div class='flex justify-start items-center space-x-2 px-4'>` +
                    `<div>` +
                    `<svg xmlns='http://www.w3.org/2000/svg' class='h-4 w-4 text-gray-500' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                                <path stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z' />
                                            </svg>` +
                    `</div>` +
                    `<div class='text-gray-800 '>` +
                    `${moment.unix(appointment.timestamp).format('ddd MMM, D [at] h:mm a')}` +
                    `</div>` +
                    `</div>` +
                    `<div class='mt-2'>` +
                    `<div class='flex justify-center space-x-2'>` +
                    `<div>` +
                    `<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">` +
                    `<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />` +
                    `<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />` +
                    `</svg>` +
                    `</div>` +
                    `<div class='text-gray-700'>` +
                    `${appointment.location}` +
                    `</div>` +
                    `</div>` +
                    `</div>` +
                    `<div class='mt-2'>` +
                    `<div class='flex justify-center space-x-2'>` +
                    `<div>` +
                    `<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">` +
                    `<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />` +
                    `</svg>` +
                    `</div>` +
                    `<div class='text-gray-700'>` +
                    `${appointment.fsr.firstName + " " + appointment.fsr.lastName}` +
                    `</div>` +
                    `</div>` +
                    `</div>` +
                    `</div>`;
            } 
            else return '';
        },
        attachSecretMessage(marker, secretMessage, participant) {
            const infowindow = new window.google.maps.InfoWindow();
            infowindow.setContent(secretMessage);
            marker.addEventListener("mouseenter", (event) => {
                // add in a little delay while 
                // opening the info window.
                setTimeout(() => {
                  if(participant.scheduleId == this.enrollment_schedule_types.SCHEDULED) {
                        const { target } = event;
                        target.childNodes[0].classList.add('marker-hover')
                        infowindow.open(marker.map, marker);
                    }
                }, 500);
            });
            marker.addEventListener("mouseleave", (event) => {
                // mimic the same delay above to close everything if passing through quickly
                setTimeout(() => {
                  if(participant.scheduleId == this.enrollment_schedule_types.SCHEDULED) {
                    const { target } = event;
                    target.childNodes[0].classList.remove('marker-hover')
                  }
                  infowindow.close();
                }, 500)
            });

            if(participant.scheduleId == this.enrollment_schedule_types.OPEN) {
                marker.addEventListener("gmp-click", () => {
                    infowindow.close();
                    this.$emit('show-fetch-participant-details-spinner');
                    this.$emit('change-participant', participant.id);
                });
            }
        },
        setEventListenersForMapClick(map) {
            map.addListener("click", () => {
                if(window.showCalendar) {
                    this.$emit('hide-appointment-blocks');
                }
            });
        },
        getSetIconObject(svg) {
          const parser = new DOMParser();
          // Create a marker svg to pass to Google's AdvancedMarkerElement
          return parser.parseFromString(
              svg,
              "image/svg+xml",
          ).documentElement;
        },
        // These SVGs have an "extra" circles to be styled on hover to change appearance of the marker.
        getSvgForScheduledParticipant() {
            return [
              '<?xml version="1.0"?>',
              '<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">',
                '<circle cx="25" cy="25" r="8.5" fill="white" stroke="#1259D1" stroke-width="3"/>',
                '<circle cx="25" cy="25" r="8.5" fill="white" stroke="#1259D1" stroke-width="3"/>',
                '<circle cx="25" cy="25" r="3.5" fill="none" stroke="white" stroke-width="1.5"/>',
              '</svg>'
            ].join('\n');
        },
        getSvgForNotScheduledParticipant() {
            return [
              '<?xml version="1.0"?>',
              '<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">',
                '<circle cx="25" cy="25" r="8.5" fill="white" stroke="#009462" stroke-width="3"/>',
                '<circle cx="25" cy="25" r="8.5" fill="white" stroke="#009462" stroke-width="3"/>',
                '<circle cx="25" cy="25" r="3.5" fill="none" stroke="white" stroke-width="2"/>',
              '</svg>'
            ].join('\n');
        },
    }
}
</script>

<style>
#map {
    position: relative !important;
}

.marker-hover {
  circle:nth-child(2) {
    fill: #337BF6;
    fill-opacity: 0.4;
    stroke-opacity: 0.1;
    stroke: white;
  }
  circle:last-child {
    fill: #1259D1;
    stroke: white;
  }
}

.gm-style-iw-d {
    color : gray;
}

.bold {
    font-weight: 600;
    font-size: 18px;
}
</style>
