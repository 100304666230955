.
<template>
  <form id="acknowledgementForm" @submit.prevent="save()">
    <div class="flex items-center px-4">
      <input
        v-model="isAcknowledged"
        required
        oninvalid="this.setCustomValidity('Please agree to this document to continue.')"
        oninput="this.setCustomValidity('')"
        type="checkbox"
        id="acknowledgement"
        class="sm:h-6 sm:w-6 h-4 w-4"
      />
      <label for="acknowledgement" class="md:text-xl sm:px-4 px-2 text-darkBlue">{{ $t('doc.agree') }} <strong>{{ docInfo?.displayText || docInfo?.title }}</strong></label>
    </div>

    <ContinueBackButtons @back="back" :secondaryButtonContent="'Preview Document'" @secondary="open"></ContinueBackButtons>
  </form>
</template>

<script>
import ContinueBackButtons from 'Views/Shared/ContinueBackButtons.vue';

export default {
  name: 'DocumentAcknowledgement',
  props: ['docInfo'],
  components: { ContinueBackButtons },
  emits: ['save', 'back'],
  data() {
    return {
      isAcknowledged: this.docInfo?.isAcknowledged,
    };
  },
  methods: {
    save() {
      this.$emit('save', this.isAcknowledged);
    },
    
    back() {
      this.$emit('back');
    },
    open() {
      window.open(this.docInfo?.docFile?.url, '_blank')
    }
  }
};
</script>

<style></style>
