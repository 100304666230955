import rolesService from './backend/roles'
import {ENV} from "@/config";

async function loadFeatureFlagList() {
	return await rolesService.getFeatureFlags();
}
function isAccessible(featureList: any[], featureName: string, agencyId: number = 0, userId: number = 0) {
	const feature = featureList.find(f => {
		return f.name === featureName
	});
	let access = feature && feature.enabled && feature.environments.includes(ENV);
	if (feature) {
		const agencyList = feature[`${ENV === 'production' ? 'agencyAccessList' : 'devAgencyAccessList'}`];
		const userList = feature[`${ENV === 'production' ? 'userAccessList' : 'devUserAccessList'}`]
		if (agencyList.length) {
			access = access && (agencyList.includes(agencyId));
		}
		if (userList.length) {
			access = access && (userList.includes(userId));
		}
	}
	return access;
}
export default { isAccessible, loadFeatureFlagList }
