<template>
  <div class="vld-parent bg-white-50">
    <loading class="ml-20" :color="color" v-model:active="isLoading" :is-full-page="fullPage" />
  </div>
  <div class="min-h-screen overflow-hidden flex flex-col">
    <!-- Top nav-->
    <MobileHeader />
    <!-- Bottom section -->
    <div class="min-h-0 flex-1 flex overflow-hidden">
      <!-- Narrow sidebar-->
      <Nav></Nav>
      <!-- Main area -->
      <main v-if="!isLoading" class="min-w-0 mb-5 flex-1 border-t border-gray-200 lg:flex bg-white">
        <section aria-labelledby="primary-heading" class="min-w-0 flex-1 h-full flex flex-col lg:order-last">
          <!-- Page Heading -->
          <section>
            <BreadcumbNav pageName="Enrollments" :linkTo="backLink"/>
            <div class="flex justify-between bg-paleBlue py-8 px-8">
              <div class="flex items-center w-1/2">
                <input
                  ref="enrollTitle"
                  :class="editEnrollment ? 'bg-transparent' : 'bg-white'"
                  class="text-3xl font-bold w-full leading-tight text-darkBlue ml-2 flex items-center"
                  type="text"
                  placeholder="Assign Packet"
                  v-model="enrollmentDisplayTitle"
                  :disabled="disabled == 0"
                  @keyup.enter="editEnrollmentName"
                />
                <span :hidden="true" @click="editEnrollmentName()" class="mx-4 tracking-wider text-gtBlue500 cursor-pointer">{{ editEnrollment ? 'EDIT' : 'DONE' }}</span>
              </div>
              <div class="mt-4 flex items-center md:mt-0 md:ml-4">
                <button
                  v-if="!newCaregiver"
                  @click="navigateToEnrollmentPresentation()"
                  type="button"
                  class="inline-flex justify-center focus:outline-none items-center h-full px-9 py-2 mx-1 rounded-3xl bg-buttonBlue hover:bg-darkBlue text-trueWhite ease-out duration-100"
                >
                  Launch Enrollment Hub
                </button>
                <button
                  v-if="!newCaregiver"
                  @click="handleReschedule()"
                  type="button"
                  class="inline-flex justify-center focus:outline-none items-center h-full px-9 py-2 mx-1 rounded-3xl bg-buttonBlue hover:bg-darkBlue text-trueWhite ease-out duration-100"
                >
                  {{scheduled ? 'Reschedule' : 'Schedule'}}
                </button>
                <button
                  v-if="!newCaregiver"
                  @click="openNotesModal(false)"
                  type="button"
                  class="inline-flex justify-center focus:outline-none items-center h-full px-9 py-2 mx-1 rounded-3xl bg-buttonBlue hover:bg-darkBlue text-trueWhite ease-out duration-100"
                >
                  Manage Notes
                </button>
              </div>
            </div>

            <div class="w-full px-8 mt-4">
              <div class="flex flex-col">
                <div :class="!enrollmentDetails.isNhep && 'mb-16'" class="lg:px-8 mt-5 lg:-mx-6">
                  <span
                    v-for="(participant, index) in enrolleesParticipant"
                    :key="participant.id"
                    @click="jumpToParticipant = participant; jumpToChoice = index; jumpToFunction = enrollmentChoiceChanged; showUnsavedChangesModal()"
                    class="font-bold mt-1 inline-flex float-left rounded-full tracking-wide cursor-pointer items-center py-1.5 px-3.5 mr-2 rounded-3xl border-solid border-2 border-buttonBlue hover:border-transparent ease-out duration-100"
                    :class="index === participantIndex ? 'bg-buttonBlue hover:bg-darkBlue text-trueWhite' : 'hover:bg-darkBlue hover:text-trueWhite text-buttonBlue'"
                  >
                    {{ participant.firstName }} {{ participant.lastName }}
                  </span>
                  <span @click="jumpToFunction = addNewCaregiver; showUnsavedChangesModal()" class="font-bold mt-1 inline-flex float-left rounded-full tracking-wide cursor-pointer items-center py-1.5 px-3.5 mr-2 rounded-3xl border-solid border-2 border-buttonBlue hover:border-transparent hover:bg-darkBlue hover:text-trueWhite text-buttonBlue ease-out duration-100">
                    Add Caregiver
                  </span>
                </div>
              </div>
              <div class="mx-2 row lg:grid block" :class="newCaregiver ? 'grid-cols-1 gap-4' : 'grid-cols-6 gap-20'">
                <!-- Participant Demographic Information -->
                <section class="col-span-4 row-span-6 lg:row-span-6 sm:row-span-12">
                  <div v-if="enrollmentDetails.isNhep" class="w-full flex items-center px-6 py-4 my-12 text-darkBlue surrounding-shadow rounded-xl">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-gold mr-6" viewBox="0 0 29.7 29.7">
                      <path d="m0 15.59v-1.77c.02-.18.05-.36.07-.54a14.91 14.91 0 0117.04-13.11 14.17 14.17 0 019.56 5.69 14.8 14.8 0 01-2.25 20.32 14.27 14.27 0 01-7.04 3.28c-.58.1-1.16.16-1.75.24h-1.52c-.25-.02-.5-.04-.75-.07a14.9 14.9 0 01-13.12-12.23c-.11-.59-.16-1.2-.24-1.8zm14.82 10.8a11.55 11.55 0 10-11.5-12.02 11.57 11.57 0 0011.5 12.02zm1.69-13.75c0 1.64.01 3.28 0 4.91a1.65 1.65 0 11-3.3-.02c-.01-.89 0-1.77 0-2.65 0-2.4 0-4.8 0-7.2a1.65 1.65 0 012.11-1.61 1.61 1.61 0 011.18 1.54c.02 1.06.01 2.12.01 3.17q0 .93 0 1.86zm0 9.49a1.64 1.64 0 01-1.65 1.64 1.65 1.65 0 11.02-3.3 1.64 1.64 0 011.63 1.66z" fill="currentColor"/>
                    </svg>
                    Please enroll a new hire for {{ enrollmentDetails.participants[0]?.firstName }} {{ enrollmentDetails.participants[0]?.lastName }}.
                    <button
                        class="ml-auto justify-center flex h-9 focus:outline-none items-center px-8 py-1 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite"
                        @click="removeNhepStatus"
                    >
                      Switch to Regular Enrollment
                    </button>
                  </div>
                  <div class="flex flex-col gap-y-4 md:flex-row item-center ">
                    <h1 class="xl:text-2xl 2xl:text-3xl text-darkBlue">{{ newCaregiver ? 'Caregiver' : participantType }} Demographic Information</h1> 
                    <div class="md:ml-auto flex">
                      <div class="flex items-center">
                        <label for="ss-switch" class="text-darkBlue mr-4 font-bold text-lg">Self-Service</label>
                        <input type="checkbox" role="switch" id="ss-switch" :checked="isSelfService" @change="selfServiceAlert">
                        <label for="ss-switch"></label>
                      </div>
                      <button @click="sendSelfServiceEmail" :disabled="!canSendSelfService" :class="canSendSelfService ? 'border-buttonBlue text-buttonBlue hover:border-transparent hover:bg-darkBlue hover:text-trueWhite' : 'border-gray-400 text-gray-400'" class="ml-5 flex justify-center focus:outline-none items-center px-12 py-1 rounded-3xl ease-out duration-100 box-border border-solid border-2">
                        Send Self-Service
                      </button>
                    </div>
                  </div>
                  
                  <hr class="border-gold my-6">

                  <section class="row-span-6 lg:row-span-6 sm:row-span-12">
                    <div class="flex pb-2">
                      <div class="flex">
                        <div v-if="!newCaregiver">
                          <!-- Participant Tabs -->
                          <span v-if="participantType === 'Participant'" class="relative inline-block">
                            <span
                              @click="participantChoiceChanged('participant')"
                              class="inline-flex float-left rounded-full cursor-pointer items-center py-1.5 px-3.5 font-bold mx-1 text-buttonBlue"
                              :class="participantChoice == 'participant' ? 'bg-paleBlue' : 'hover:bg-paleBlue'"
                            >
                              Participant
                            </span>
                            <span
                              v-if="participantFieldsReq"
                              class="absolute top-0 right-5 inline-flex items-center justify-center px-2 py-1 leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full"
                              >!</span
                            >
                          </span>

                          <span v-if="participantType === 'Participant'" class="relative inline-block">
                            <span
                              @click="participantChoiceChanged('employers')"
                              class="inline-flex float-left rounded-full cursor-pointer items-center py-1.5 px-3.5 font-bold mx-1 text-buttonBlue"
                              :class="participantChoice == 'employers' ?'bg-paleBlue' : 'hover:bg-paleBlue'"
                            >
                              Employer of Record
                            </span>
                            <span
                              v-if="employerFieldsReq"
                              class="absolute top-0 right-5 inline-flex items-center justify-center px-2 py-1 leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full"
                              >!</span
                            >
                          </span>

                          <span v-if="participantType === 'Participant'" class="relative inline-block">
                            <span
                              @click="participantChoiceChanged('representative')"
                              class="inline-flex float-left rounded-full cursor-pointer items-center py-1.5 px-3.5 font-bold mx-1 text-buttonBlue"
                              :class="participantChoice == 'representative' ?'bg-paleBlue' : 'hover:bg-paleBlue'"
                            >
                              Representative
                            </span>
                            <span
                              v-if="representativeFieldsReq"
                              class="absolute top-0 right-5 inline-flex items-center justify-center px-2 py-1 leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full"
                              >!</span
                            >
                          </span>

                          <span v-if="participantType === 'Participant'" class="relative inline-block">
                            <span
                              @click="participantChoiceChanged('caseManager')"
                              class="inline-flex float-left rounded-full cursor-pointer items-center py-1.5 px-3.5 font-bold mx-1 text-buttonBlue"
                              :class="participantChoice == 'caseManager' ?'bg-paleBlue' : 'hover:bg-paleBlue'"
                            >
                              Case Manager & Authorization
                            </span>
                            <span
                              v-if="caseManagerFieldsReq"
                              class="absolute top-0 right-5 inline-flex items-center justify-center px-2 py-1 leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full"
                              >!</span
                            >
                          </span>

                          <span v-if="participantType === 'Participant' && isCT" class="relative inline-block">
                            <span
                              @click="participantChoiceChanged('vendorInfo')"
                              class="inline-flex float-left rounded-full cursor-pointer items-center py-1.5 px-3.5 font-bold mx-1 text-buttonBlue"
                              :class="participantChoice == 'vendorInfo' ?'bg-paleBlue' : 'hover:bg-paleBlue'"
                            >
                              Vendor Info
                            </span>
                          </span>

                          <!-- Caregiver Tabs -->
                          <span v-if="participantType === 'Caregiver'" class="relative inline-block">
                            <span
                              @click="participantChoiceChanged('caregiver')"
                              class="inline-flex float-left rounded-full cursor-pointer items-center py-1.5 px-3.5 font-bold mx-1 text-buttonBlue"
                              :class="participantChoice == 'caregiver' ?'bg-paleBlue' : 'hover:bg-paleBlue'"
                            >
                              Demographic Information
                            </span>
                            <span
                              v-if="demographicFieldsReq"
                              class="absolute top-0 right-5 inline-flex items-center justify-center px-2 py-1 leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full"
                              >!</span
                            >
                          </span>

                          <span v-if="participantType === 'Caregiver'" class="relative inline-block">
                            <span
                              @click="participantChoiceChanged('caregiver-caregiverDetails')"
                              class="inline-flex float-left rounded-full cursor-pointer items-center py-1.5 px-3.5 font-bold mx-1 text-buttonBlue"
                              :class="participantChoice == 'caregiver-caregiverDetails' ?'bg-paleBlue' : 'hover:bg-paleBlue'"
                            >
                              Employment Information
                            </span>
                            <span
                              v-if="employmentFieldsReq"
                              class="absolute top-0 right-5 inline-flex items-center justify-center px-2 py-1 leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full"
                              >!</span
                            >
                          </span>

                          <!-- Vendor Tabs -->
                          <span v-if="participantType === 'Vendor'" class="relative inline-block">
                            <span
                              @click="participantChoiceChanged('vendor')"
                              class="inline-flex float-left rounded-full cursor-pointer items-center py-1.5 px-3.5 font-bold mx-1 text-buttonBlue"
                              :class="participantChoice == 'vendor' ?'bg-paleBlue' : 'hover:bg-paleBlue'"
                            >
                              Vendor Information
                            </span>
                          </span>
                        </div>

                        <div v-if="newCaregiver">
                          <!-- New Caregiver Tabs -->
                          <span v-if="participantType === 'add_caregiver'" class="relative inline-block">
                            <span
                              @click="participantChoiceChanged('caregiver')"
                              class="inline-flex float-left rounded-full cursor-pointer items-center py-1.5 px-3.5 font-bold mx-1 text-buttonBlue"
                              :class="participantChoice == 'caregiver' ?'bg-paleBlue' : 'hover:bg-paleBlue'"
                            >
                              Demographic Information
                            </span>
                            <span
                              v-if="demographicFieldsReq"
                              class="absolute top-0 right-5 inline-flex items-center justify-center px-2 py-1 leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full"
                              >!</span
                            >
                          </span>

                          <span v-if="participantType === 'add_caregiver'" class="relative inline-block">
                            <span
                              @click="participantChoiceChanged('caregiver-caregiverDetails')"
                              class="inline-flex float-left rounded-full cursor-pointer items-center py-1.5 px-3.5 font-bold mx-1 text-buttonBlue"
                              :class="participantChoice == 'caregiver-caregiverDetails' ?'bg-paleBlue' : 'hover:bg-paleBlue'"
                            >
                              Employment Information
                            </span>
                            <span
                              v-if="employmentFieldsReq"
                              class="absolute top-0 right-5 inline-flex items-center justify-center px-2 py-1 leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full"
                              >!</span
                            >
                          </span>
                        </div>
                      </div>

                      <button
                        type="submit"
                        form="enrolleesForm"
                        value="Submit"
                        class="ml-auto justify-center flex h-9 focus:outline-none items-center px-12 py-1 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite"
                      >
                        Save
                      </button>
                    </div>

                    <div class="my-4 pt-0" :class="showSpinner ? 'opacity-0' : ''">
                      <form id="enrolleesForm" @submit.prevent="saveEnrollees()" @keypress.enter.prevent>
                      <!-- Add New Caregiver -->
                        <div v-if="newCaregiver" class="grid grid-cols-6 lg:grid-cols-12 gap-4 items-end">
                          <template v-if="participantChoice === 'caregiver'">
                            <CaregiverInformation :isNewCaregiver="true" :caregiverData="enrolleesDetails" @demographicFormData="getDemographicData" @emitParticipantType="getParticipantType" />
                          </template>
                          <template v-if="participantChoice === 'caregiver-caregiverDetails'">
                            <EmploymentInformation :isNewCaregiver="true" :employeeData="enrolleesDetails" @employeeFormData="getEmployeeData" @emitParticipantType="getParticipantType" :caseManagerData="enrolleesDetails" @caseManagerFormData="getCaseManagerData"/>
                          </template>
                        </div>

                        <div v-else class="grid grid-cols-6 lg:grid-cols-12 gap-4 items-end">
                          <!-- Participant Form Fields -->
                          <template v-if="participantChoice === 'participant'">
                            <ParticipantInformation :participantData="enrolleesDetails" @participantFormData="getParticipantData" @emitParticipantType="getParticipantType" @unsavedChange="unsavedChanges" />
                          </template>
                          <template v-if="participantChoice === 'employers'">
                            <EmployerRecord :employerData="enrolleesDetails" :participantIsEOR="participantIsEOR" @employerFormData="getEmployerData" @employerIsParticipant="setEmployerAsParticipant" @unsavedChange="unsavedChanges" />
                          </template>
                          <template v-if="participantChoice === 'representative'">
                            <Representative :representativeData="enrolleesDetails" @representativetFormData="getRepresentativetData" @unsavedChange="unsavedChanges" />
                          </template>
                          <template v-if="participantChoice === 'caseManager'">
                            <CaseManager :caseManagerData="enrolleesDetails" @caseManagerFormData="getCaseManagerData" @unsavedChange="unsavedChanges" />
                          </template>
                          <template v-if="participantChoice === 'vendorInfo' && isCT">
                            <ParticipantVendorInfo :vendorInfoData="enrolleesDetails" @vendorInfoFormData="getParticipantVendorInfoData" @unsavedChange="unsavedChanges" />
                          </template>

                          <!-- Caregiver Form Fields -->
                          <template v-if="participantChoice === 'caregiver'">
                            <CaregiverInformation :caregiverData="enrolleesDetails" @demographicFormData="getDemographicData" @emitParticipantType="getParticipantType" @unsavedChange="unsavedChanges" />
                          </template>
                          <template v-if="participantChoice === 'caregiver-caregiverDetails'">
                            <EmploymentInformation :employeeData="enrolleesDetails" @employeeFormData="getEmployeeData" @emitParticipantType="getParticipantType" @unsavedChange="unsavedChanges" />
                          </template>

                          <!-- Vendor Form Fields -->
                          <template v-if="participantChoice === 'vendor'">
                            <VendorInformation :vendorData="enrolleesDetails" @vendorFormData="getVendorData" @emitParticipantType="getParticipantType" />
                          </template>
                        </div>
                      </form>
                    </div>
                    <!-- Federal Documents -->
                    <div class="row-span-6 lg:row-span-6 sm:row-span-12 pt-4 mt-16" v-if="!newCaregiver && !(enrollmentDetails.isNhep && participantType === 'Participant')">
                      <div class="flex items-center">
                        <h1 class="xl:text-2xl 2xl:text-3xl text-darkBlue">
                          {{ changedPacketName }}
                        </h1>
                        <button type="button" @click="showChangePacketModal()" 
                          class="inline-flex justify-center focus:outline-none items-center px-6 py-1 mx-2 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite"
                        >{{ checkPacketExist }}</button>
                        <button type="button" @click="showAddDocumentModal"
                          class="inline-flex justify-center focus:outline-none items-center px-6 py-1 mx-2 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite"
                        >Add Documents</button>
                      </div>
                      <section class="my-6">
                        <DocumentsTable title="Federal Documents" :documents="federalDocuments" :displayText="true" @getenrollees="getEnrollees(enrolleeId)"></DocumentsTable>
                      </section>
                      <!-- State Documents -->
                      <section class="my-6">
                        <DocumentsTable title="State Documents" :documents="stateDocuments" :displayText="true" @getenrollees="getEnrollees(enrolleeId)"></DocumentsTable>
                      </section>
                      <!-- Program Documents -->
                      <section class="my-6">
                        <DocumentsTable title="Program Documents" :documents="programDocuments" :displayText="true" @getenrollees="getEnrollees(enrolleeId)"></DocumentsTable>
                      </section>
                      <!-- Miscellaneous Documents -->
                      <section class="my-6">
                        <DocumentsTable title="Miscellaneous Documents" :documents="miscellaneousDocuments" :displayText="true" @getenrollees="getEnrollees(enrolleeId)"></DocumentsTable>
                      </section>
                      <!-- Image capture Documents -->
                      <section class="my-6">
                        <DocumentsTable title="Document Image Capture" :documents="imageCaptureDocuments" :displayText="true" @getenrollees="getEnrollees(enrolleeId)"> </DocumentsTable>
                      </section>
                    </div>
                  </section>
                </section>

                <!-- FSR - FL Sunshine Employee Packet -->
                <section v-if="!newCaregiver" class="row-span-4 lg:row-span-4 sm:row-span-12 col-span-2">
                  <div class="w-full py-6 px-6 bg-white surrounding-shadow rounded-lg my-4 text-darkBlue">
                    <h2 class="font-bold text-2xl pb-8">Enrollment Summary</h2>
                    <div class="mb-8">
                      <h3 class="font-bold text-lg mb-2">Enrollment Status</h3>
                      <div class="grid grid-rows-2 2xl:grid-rows-none 2xl:grid-cols-12 gap-4">
                        <div class="2xl:col-span-7 rounded-lg bg-iceBlue flex px-3 py-2">
                          <p class="font-bold text-sm">
                            {{enrollmentDetails?.status?.name}}
                          </p>
                        </div>
                        <button v-if="(!enrollmentDetails.isArchived || currentUser.roleId !== 6) && (enrollmentDetails.statusId === enrollment_status_types.POST_ENROLLMENT || enrollmentDetails.statusId === enrollment_status_types.IN_PROCESS)" type="button"
                            @click="completeEnrollment()"
                            class="xl:col-span-5 whitespace-nowrap px-3 py-2 w-full h-10 justify-center items-center flex rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite">
                          Mark Complete
                        </button>
                        <button v-if="enrollmentDetails.isArchived && currentUser.roleId === 6" type="button"
                            @click="returnEnrollmentToActive()"
                            class="xl:col-span-5 whitespace-nowrap px-3 py-2 w-full h-10 justify-center items-center flex rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite">
                          Return to Active
                        </button>
                      </div>
                    </div>

                    <div class="mb-8">
                      <h3 class="font-bold text-lg">Enrollment Meeting</h3>
                      <div class="grid grid-rows-2 xl:grid-rows-none xl:grid-cols-12 gap-4">
                        <div class="xl:col-span-7 rounded-lg bg-iceBlue flex flex-col px-3 py-2">
                          <p v-if="scheduled">
                            {{ enrollmentDetails?.schedule }}
                          </p>
                          
                          <p v-if="scheduled" class="font-bold text-sm">{{ scheduledDate(enrollmentDetails) }} {{ scheduledTime(enrollmentDetails).toLowerCase() }}</p>
                          <p v-else class="font-bold text-sm">Not Scheduled</p>
                          <p class="text-sm"> {{ scheduledFSR(enrollmentDetails) }}{{ (scheduled == enrollment_schedule_types.SCHEDULED_VIRTUAL) ? " | Virtual Meeting" : "" }}</p>
                        </div>

                        <button @click="handleReschedule()" 
                            class="xl:col-span-5 px-3 py-2 w-full h-10 justify-center items-center flex rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite">
                          {{scheduled ? 'Reschedule' : 'Schedule'}}
                        </button>
                      </div>
                    </div>

                    <div class="mb-8">
                      <h3 class="font-bold text-lg">Enrollment Assignment</h3>
                      <div class="grid grid-rows-2 xl:grid-rows-none xl:grid-cols-12 gap-4">
                        <div class="xl:col-span-7">
                          <div v-if="editingAssignment" class="contents">
                            <Multiselect
                              v-model="selectedAgentId"
                              class="surrounding-shadow cursor-pointer w-full h-full rounded-lg"
                              placeholder="Search"
                              :options="agentMultiselect"
                              :searchable="true"
                            />
                          </div>
                          <div v-else class="rounded-lg bg-iceBlue flex flex-col px-3 h-full py-2">
                            <p v-if="enrollmentDetails.enrollmentSpecialist" class="font-bold text-sm">{{ enrollmentDetails.enrollmentSpecialist.fullName }}</p>
                            <p v-else class="font-bold text-sm">Not Assigned</p>
                          </div>
                        </div>
                        <button @click="handleAssign()" 
                            class="xl:col-span-5 px-3 py-2 w-full h-10 justify-center items-center flex rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite">
                          {{ enrollmentDetails?.enrollmentSpecialist ? 'Reassign' : 'Assign' }}
                        </button>
                      </div>
                    </div>

                    <div class="mb-8">
                      <h3 class="font-bold text-lg">Enrollment Status</h3>
                      <div v-for="enrollee of enrolleesParticipant" :key="enrollee.id" class="py-1 ml-2 grid grid-rows-2 last:border-b-0 border-b border-b-gold xl:grid-rows-none xl:grid-cols-9">
                        <p class="font-bold xl:col-span-5"> {{ enrollee.firstName }} {{ enrollee.lastName }} </p>
                        <p class="xl:col-span-4">
                          <span v-if="!enrollee.ownedEnvelope">Not sent for signature</span>
                          <span v-else-if="enrollee.ownedEnvelope?.signingStatus === 0">Sent for signature</span>
                          <span v-else-if="enrollee.ownedEnvelope?.signingStatus === 1">Signed</span>
                          <span v-else>Unknown</span>
                        </p>
                      </div>
                    </div>

                    <div>
                      <h3 class="font-bold text-lg">Enrollment Notes</h3>
                      <div class="mb-3 p-2 rounded-lg" v-for="note of notes" :key="note.id" :class="(notes.indexOf(note) % 2 == 0) ? '' : 'bg-iceBlue'">
                        <p class="leading-5 tracking-normal text-darkBlue">
                          <b>
                            {{ getFormattedDate(note.createdAt, 'l') }} {{ getFormattedDate(note.createdAt, 'LT').toLowerCase() }}–{{ note.user ? `${note.user.firstName} ${note.user.lastName}` : 'System' }}
                          </b>
                          {{ note.text }}
                        </p>
                      </div> 
                      <a @click="openNotesModal(true)" class="underline cursor-pointer text-buttonBlue hover:text-darkBlue">View All Notes ></a>
                      <button type="button" 
                          @click="openNotesModal(false)"
                          class="mt-4 block min-w-1/2 justify-center focus:outline-none items-center px-6 py-1 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite">
                        Add Note
                      </button>
                    </div>
                  </div>
                  
                  <div v-if="[1, 6].includes(currentUser.roleId)" class="w-full py-6 px-6 bg-white surrounding-shadow rounded-lg my-4 text-darkBlue">
                    <h2 class="font-bold text-2xl pb-8">Admin Configuration</h2>
                    <div>
                      <h3 class="font-bold text-lg">DocuSign Status</h3>
                      <div v-for="enrollee of enrolleesParticipant" :key="enrollee.id" class="mb-3">
                        <div class="grid grid-rows-2 xl:grid-rows-none xl:grid-cols-12 gap-4">
                          <div class="xl:col-span-7 rounded-lg bg-iceBlue flex flex-col px-3 py-2">
                            <p class="font-bold text-sm"> {{ enrollee.firstName }} {{ enrollee.lastName }} </p>
                            <p class="text-sm"> {{ signType[enrollee.ownedEnvelope?.signingMethod] || 'N/A'}} </p>
                          </div>
  
                          <button @click="nullifyDocusignSelection(enrollee.id)" class="xl:col-span-5 px-3 py-2 w-full h-10 justify-center items-center flex rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite">
                            Nullify {{ getTypeById(enrollee.type) }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </section>
      </main>
    </div>
  </div>

  <div v-if="showNotesModal">
    <NotesModal :enrolleeIds="enrolleesParticipant.map(e => e.id).join(',')" :currentEnrollee="currentEnrollee.id" :readOnly="readOnlyNotes" @submit="setNotesRefresh(true)" @dismiss="dismissModal" @updateReadOnly="readOnlyNotes = $event" />
  </div>
  <div v-if="showAddDocumentsModal" class="doc-modal">
    <AddDocumentsModal :enrollee="currentEnrollee" :packetId="packetId" @dismissDocModal="dismissModal" @closeModalFromBackDrop="dismissModal" @getenrollees="getEnrollees(enrolleeId)" />
  </div>
  <div v-if="showChangePacketsModal" class="doc-modal">
    <ChangePacketModal
      :currentPacketName="enrolleesDetails.documents.packet.name"
      :enrolleeID="enrolleeId"
      @dismissModal="dismissModal"
      @closeModalFromBackDrop="dismissModal"
      @changePacketName="changePacket"
      @getenrollees="getEnrollees(enrolleeId)"
    />
  </div>
  <div v-if="isPacketAssigned" class="doc-modal">
    <SingleActionModal @clearDoc="dismissModal" @dismissDocModal="dismissModal" modalTitle="Packet Not Assigned!" modalText="You must assign a packet to add Documents" />
  </div>
  <div v-if="showRescheduleAppointmentModal">
    <rescheduleAppointmentModal @cancelAppointment="cancelAppointment" @closeModal="dismissModal" />
  </div>
  <div v-if="showSelfServiceModal">
    <ConfirmationModal
      @confirm="isSelfService ? setSelfServiceFalse() : setSelfServiceTrue()"
      @dismiss="dismissModal"
      modalTitle="Self-Service"
      :modalText="isSelfService ? 'Are you sure you want to remove self-service?' : 'Are you sure you want to select self-service for the enrollment?'"
    />
  </div>
  <div class="fixed flex justify-center my-3 left-1/3 -ml-20 top-2/3 text-gray-500" v-if="showSpinner">
    <svg class="animate-spin -ml-1 mr-3 h-8 w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  </div>

  <SimpleAlertModal
      v-if="showUnsavedChanges"
      modalTitle="Unsaved Changes"
      modalText="You have unsaved changes. Leaving this tab before saving will result in those changes being lost!"
      modalButtonCancelText="Cancel"
      modalButtonActionText="Proceed"
      @dismissDocModal="closeModal()"
      @clearDoc="jumpToFunction()"
  />
</template>

<script>
import enrollmentService from 'Services/backend/enrollments';
import docusignService from 'Services/backend/docusign';
import userFunctions from 'Utilities/userFunctions'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import ParticipantInformation from './ParticipantInformation/ParticipantInfo.vue';
import EmployerRecord from './ParticipantInformation/EmployerRecord.vue';
import Representative from './ParticipantInformation/Representative.vue';
import CaseManager from './ParticipantInformation/CaseManager.vue';
import ParticipantVendorInfo from './ParticipantInformation/ParticipantVendorInfo.vue';
import CaregiverInformation from './CaregiverInformation/CaregiverInfo.vue';
import EmploymentInformation from './CaregiverInformation/EmploymentInfo.vue';
import VendorInformation from './VendorInformation/VendorInfo.vue';
import AddDocumentsModal from './Modal/AddDocumentsModal.vue';
import ChangePacketModal from 'Shared/Modal/ChangePacketModal.vue';
import DocumentsTable from './Tables/DocumentsTable.vue';
import SingleActionModal from 'Shared/Modal/SingleActionModal.vue';
import NotesModal from 'Shared/Modal/NotesModal.vue';
import { getFormattedDate, getFormattedUnixDate } from 'Utilities/helper';
import edpService from './service';
import {ENROLLMENTS_SCHEDULE_TYPES, SIGNING_TYPE, ENROLLMENT_STATUS_TYPES} from '@/config';
import rescheduleAppointmentModal from 'Views/EnrollmentPresentation/TemplateInstances/rescheduleAppointmentModal.vue';
import Multiselect from '@vueform/multiselect';
import SimpleAlertModal from "@/views/Shared/Modal/SimpleAlertModal.vue";
import {mapStores} from "pinia";
import {useScheduleStore} from "@/stores/schedule";

export default {
  name: 'EnrollmentsDetails',
  components: {
    SimpleAlertModal,
    Loading,
    ParticipantInformation,
    CaregiverInformation,
    EmploymentInformation,
    VendorInformation,
    EmployerRecord,
    Representative,
    CaseManager,
    AddDocumentsModal,
    Multiselect,
    ChangePacketModal,
    DocumentsTable,
    SingleActionModal,
    NotesModal,
    rescheduleAppointmentModal,
    ParticipantVendorInfo
  },
  async mounted() {
    if (window['_genesysJs']) {
      document.getElementById('genesys-messenger').style.display = 'none';
    }
    this.currentUser = await userFunctions.getUserInfo();
    this.enrollment_schedule_types = ENROLLMENTS_SCHEDULE_TYPES;
    this.enrollment_status_types = ENROLLMENT_STATUS_TYPES;
    this.signType = SIGNING_TYPE;
    this.getEnrollmentDetails();
  },
  data() {
    return {
      showUnsavedChanges: false,
      notes: [],
      scheduled: false,
      isOpen: false,
      isModalOpen: false,
      isLoading: false,
      color : '#2F4C5D',
      fullPage: true,
      disabled: 0,
      participantType: 'Participant',
      enrollmentTitle: '',
      enrollmentDisplayTitle: '',
      editEnrollment: true,
      participantChoice: '',
      enrollment: {
        id: '',
      },
      enrollmentDetails: {},
      enrolleeId: '',
      enrolleesParticipant: [],
      enrolleesDetails: {
      },
      isCT: false,
      defaultCaregiver: {
        firstName: '',
        middleInitial: '',
        lastName: '',
        altNameContact: '',
        phonePrimary: '',
        phoneSecondary: '',
        email: null,
        dateOfBirth: '',
        gender: null,
        socialSecurityNumber: '',
        regionId: '1',
        addressPrimary: {
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          zipcode: '',
          county: '',
        },
        addressSecondary: {},
        driverLicenseId: '',
        stateOfBirth: '',
        raceId: null,
        startDate: null,
        countryOfBirth: '',
        caregiverDetails: {
            gtEmployeeId: null,
            gtAssignmentId: null,
            startDate: null,
            citizenStatus: null,
            uscisNumber: null,
            authorizedToWorkUntil: null,
            i94AdmissionNumber: null,
            foreignPassportNumber: null,
            countryOfIssuance: null,
            listADocumentTitle: null,
            listAIssuingAuthority: null,
            listADocumentNumber: null,
            listAExpirationDate: null,
            listBDocumentTitle: null,
            listBIssuingAuthority: null,
            listBDocumentNumber: null,
            listBExpirationDate: null,
            listCDocumentTitle: null,
            listCIssuingAuthority: null,
            listCDocumentNumber: null,
            listCExpirationDate: null,
        }
      },
      federalDocuments: [],
      stateDocuments: [],
      programDocuments: [],
      miscellaneousDocuments: [],
      imageCaptureDocuments: [],
      showAddDocumentsModal: false,
      showChangePacketsModal: false,
      showSelfServiceModal: false,
      showNotesModal: false,
      notesNeedRefresh: false,
      readOnlyNotes: false,
      participantIndex: '',
      changedPacketName: '',
      checkPacketExist: '',
      packetStateAbbrev: '',
      packetId: '',
      showSpinner: false,
      participantFieldsReq: false,
      employerFieldsReq: false,
      representativeFieldsReq: false,
      caseManagerFieldsReq: false,
      demographicFieldsReq: false,
      employmentFieldsReq: false,
      fetchParticipantData: {},
      fetchEmployerData: {},
      fetchRepresentativeData: {},
      fetchCaseManagerData: {},
      fetchParticipantVendorInfoData: {},
      fetchDemographicData: {},
      fetchEmployeeData: {},
      fetchVendorData: {},
      isDataFetched: false,
      isPacketAssigned: false,
      errorMessage: '',
      newCaregiver: false,
      currentEnrollee: {},
      enrollment_schedule_types: {},
      enrollment_status_types: {},
      showRescheduleAppointmentModal: false,
      participantIsEOR: false,
      originalEnrolleeDetails: {
      },
      participantTypes: {
        'Participant': 1,
        'Caregiver': 2,
        'Vendor': 3,
      },
      backLink: '',
      signType: {},
      currentUser: {},
      selectedAgentId: null,
      agentMultiselect: [],
      agentList: [],
      editingAssignment: false,
      changesToSave: [],
      jumpToChoice: null,
      jumpToParticipant: null,
      jumpToFunction: null
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.backLink = from.href
    })
  },
  methods: {
    getFormattedDate,
    openNotesModal(readOnly) {
      this.readOnlyNotes = readOnly;
      this.showNotesModal = true;
    },
    refreshNotes() {
      this.getNotes();
    },
    setNotesRefresh(refresh) {
      this.notesNeedRefresh = refresh;
    },
    navigateToEnrollmentPresentation() {
      this.$router.push({ name: 'FirstEnrollmentSlide', params: { enrollmentId: this.enrollmentDetails.id } });
    },
    handleReschedule() {
      if(this.scheduled) {
        this.openRescheduleAppointmentModal();
      } else {
        this.scheduleOpenEnrollment(this.enrollmentDetails);
      }
    },
    openRescheduleAppointmentModal() {
      this.showRescheduleAppointmentModal = true;
    },
    cancelAppointment(reason) {
      this.showRescheduleAppointmentModal = false;
      // save a note for the cancellation
      const cancelNote = {
        text: `Meeting cancelled - Was scheduled at ${this.scheduledDate(this.enrollmentDetails)} ${this.scheduledTime(this.enrollmentDetails)} - ${reason}`,
        userId: this.currentUser.id,
        enrolleeId: this.currentEnrollee.id
      }
      enrollmentService.saveNotes(cancelNote);
      // set enrollment to open
      enrollmentService.updateStatusAndScheduleType({enrollmentId: this.enrollmentDetails.id, scheduleId: ENROLLMENTS_SCHEDULE_TYPES.OPEN});
      this.scheduled = 0;
    },
    scheduleOpenEnrollment(enrollment) {
      const params = {
        agency: enrollment.agencyId,
        state: enrollment.region.stateAbbrev,
      };
      this.scheduleStore.setEnrollmentId(enrollment.participants[0].id);
      this.scheduleStore.setIfFromEnrollments(true);
      this.scheduleStore.setScheduleData(params);
      this.$router.push({ name: 'ScheduleParticipantList' });
    },
    handleAssign() {
      if (this.editingAssignment) {
        enrollmentService
          .updateAssignment(this.enrollmentDetails.id, this.selectedAgentId)
          .then(() => {
            this.enrollmentDetails.enrollmentSpecialist = this.agentList.find(agent => agent.id === this.selectedAgentId) ?? null;
            this.editingAssignment = false;
          })
          .catch(() => {
            this.$toast(false, 'Error', 'Failed to assign packet');
          });
      } else {
        this.selectedAgentId = this.agentList.find(agent => agent.id === this.enrollmentDetails.enrollmentSpecialist?.id)?.id ?? null;
        this.editingAssignment = true;
      }
    },
    getEnrollmentDetails() {
      enrollmentService.getEnrollment(this.$route.params.id).then(res => {
        let details = res.data[0];
        this.enrollmentDetails = details;
        this.scheduled = this.checkScheduledOrVirtual(details);
        this.getAgentList();
      });
    },
    getAgentList() {
      const params = { agency: this.enrollmentDetails.agencyId };
      enrollmentService.getAgentList(params).then(
        (response) => {
          this.agentList = Array
            // Makes array not have duplicates based on userId
            .from(new Map(response.data.map((item) => [item.userId, item.user])).values())
            .filter(Boolean)

            // Converts to MultiSelect object
            this.agentMultiselect = this.agentList.map((user) => ({value: user.id, label: user.fullName}));
        },
        (error) => {
          console.error(error);
        },
      );
    },
    checkScheduledOrVirtual(enrollment) {
      if (!enrollment) return 0;
      if (enrollment.scheduleId == this.enrollment_schedule_types.SCHEDULED || enrollment.scheduleId == this.enrollment_schedule_types.SCHEDULED_VIRTUAL) return enrollment.scheduleId;
      else return 0;
    },
    scheduledFSR(enrollment) {
      return enrollment.participants[0]?.appointments?.length ? enrollment.participants[0]?.appointments[enrollment.participants[0]?.appointments?.length - 1]?.fsr?.fullName : '';
    },
    scheduledDate(enrollment){
      return enrollment.participants[0]?.appointments?.length ? getFormattedUnixDate(enrollment.participants[0]?.appointments[enrollment.participants[0].appointments.length - 1].timestamp, 'l') : ''
    },
    scheduledTime(enrollment){
      return enrollment.participants[0]?.appointments?.length ? getFormattedUnixDate(enrollment.participants[0]?.appointments[enrollment.participants[0].appointments.length - 1].timestamp, 'LT') : ''
    },  
    enrollmentChoiceChanged() {
      this.participantIndex = this.jumpToChoice;
      this.getEnrollees(this.jumpToParticipant.id);
      this.currentEnrollee = this.jumpToParticipant;
      this.newCaregiver = false;
      this.showUnsavedChanges = false;
    },
    participantChoiceChanged(choice) {
      this.participantChoice = choice;
    },
    editEnrollmentName() {
      this.focusInput();
      if (this.enrollmentTitle.length > 1) {
        this.editEnrollment = !this.editEnrollment;
        this.disabled = (this.disabled + 1) % 2;

        if (this.editEnrollment) {
          const body = {
            name: this.enrollmentTitle,
            stateAbbrev: this.packetStateAbbrev,
          };

          enrollmentService.updatePacketName(this.packetId, body).then(
            () => {
              this.isLoading = false;
              this.$toast(true, 'Packet name changed Successfully');
            },
            (error) => {
              console.error(error);
            },
          );
        }
      }
    },
    focusInput() {
      this.$refs.enrollTitle.focus();
    },
    getEnrolleesList() {
      this.isLoading = true;
      enrollmentService.getEnrolleesList(this.$route.params.id).then(
        (response) => {
          this.enrolleesParticipant = response.data;

          this.enrolleesParticipant.sort((participantA, participantB) => {
            if (participantA.type > participantB.type) return 1;
            if (participantA.type < participantB.type) return -1;
            return 0;
          });
          this.enrollmentDisplayTitle = `${this.enrolleesParticipant[0].firstName} ${this.enrolleesParticipant[0].lastName}'s Enrollment`;

          this.determineUrlParams();
          if (this.participantType !== 'add_caregiver') {
            const index = this.enrolleesParticipant.findIndex(e => e.type === this.participantTypes[this.participantType])
            this.getEnrollees(this.enrollmentId[index]);
            this.currentEnrollee = this.enrolleesParticipant[index];
            this.participantIndex = index;
          } else {
            this.isLoading = false;
          }
          this.getEnrollmentDetails();
          this.getNotes();
        },
        (error) => {
          console.error(error.response);
          this.isLoading = false;
        },
      );
    },
    getEnrollees(enrolleeId) {
      this.isLoading = true;
      this.enrolleeId = enrolleeId;
      this.changesToSave = [];
      enrollmentService.getEnrollees(enrolleeId).then(
        (response) => {
          if (response.code == 200) {
            this.isLoading = false;
            this.isDataFetched = true;
            this.enrolleesDetails = response?.data[0];
            this.originalEnrolleeDetails = structuredClone(this.enrolleesDetails);
            this.enrolleesDetails.participant ? (this.participantChoice = 'participant') : 'caregiver';
            this.enrolleesDetails.caregiver ? (this.participantChoice = 'caregiver') : 'participant';
            this.enrolleesDetails.vendor ? (this.participantChoice = 'vendor') : '';
            this.packetId = this.enrolleesDetails.documents.packet.id;

            if (response.data[0].documents.packet.name) {
              this.changedPacketName = response.data[0].documents.packet.name;
              this.enrollmentTitle = response.data[0].documents.packet.name;
              this.packetStateAbbrev = response.data[0].documents.packet.stateAbbrev;
              this.checkPacketExist = 'Change Packet';
            } else {
              this.changedPacketName = '';
              this.checkPacketExist = 'Assign Packet';
            }
            this.isCT = response.data[0]?.documents?.packet?.stateAbbrev === 'CT';

            this.categoriseDocuments();
          } else {
            this.isDataFetched = false;
            this.errorMessage = response.message;
            this.$router.push({ name: 'Error', params: { error: response.message } });
            this.isLoading = false;
          }
        },
        (error) => {
          this.isLoading = false;
          console.error(error);
        },
      );
    },
    categoriseDocuments() {
      const packet = this.enrolleesDetails.documents?.packet ?? {};
      const enrollee = this.enrolleesDetails.documents?.enrollee ?? {};

      this.federalDocuments = this.mergeDocuments(packet?.federalDocuments, enrollee.federalDocuments);
      this.stateDocuments = this.mergeDocuments(packet?.stateDocuments, enrollee.stateDocuments);
      this.programDocuments = this.mergeDocuments(packet?.programDocuments, enrollee.programDocuments);
      this.miscellaneousDocuments = this.mergeDocuments(packet?.miscellaneousDocuments, enrollee.miscellaneousDocuments);
      this.imageCaptureDocuments = this.mergeDocuments(packet?.imageCaptureDocuments, enrollee.imageCaptureDocuments);
    },
    mergeDocuments(packetDocuments, enrolleeDocuments) {
      return [...(packetDocuments?.length ? packetDocuments : []), ...(enrolleeDocuments?.length ? enrolleeDocuments : [])];
    },
    goToEnrollmentsPage() {
      this.$router.push({ name: 'Enrollments' });
    },
    showAddDocumentModal() {
      if (!this.currentEnrolleeId) this.currentEnrolleeId = this.enrolleeId;
      if (this.enrolleesDetails.documents.packet.id) {
        this.showAddDocumentsModal = true;
      } else {
        this.isPacketAssigned = true;
      }
    },
    showChangePacketModal() {
      this.showChangePacketsModal = true;
    },
    dismissModal() {
      this.showAddDocumentsModal = false;
      this.showChangePacketsModal = false;
      this.showNotesModal = false;
      this.isPacketAssigned = false;
      this.showRescheduleAppointmentModal = false;
      this.showSelfServiceModal = false;
      //Determine if the on-screen notes need to be updated.
      if (this.notesNeedRefresh) {
        this.refreshNotes();
        this.setNotesRefresh(false);
      }
    },
    changePacket(packetName) {
      this.changedPacketName = packetName;
      this.enrollmentTitle = packetName;
      this.dismissModal();
    },
    saveEnrollees() {
      this.isLoading = true;
      if (this.newCaregiver) {
        let body = {
          type: 'CAREGIVER',
          basicInfo: {
            ...this.fetchDemographicData,
            enrollmentId: this.$route.params.id,
          },
          caregiverOtherInformation: {
            ...this.fetchDemographicData,
            ...this.fetchEmployeeData,
          },
        };
        enrollmentService.addNewCaregiver(body).then(
          (response) => {
            edpService.processEnrolleeSavedResponse(response, this.$toast.bind(this));
            if (!response.status) {
              setTimeout(() => {
                location.reload();
              }, 1500);
            } else {
              this.isLoading = false;
            }
          },
          (error) => {
            console.error(error);
            this.isLoading = false;
          },
        );
      } else {
        if (this.participantType === 'Participant') {
          if (Object.keys(this.fetchRepresentativeData || {}).length == 0) {
            this.fetchRepresentativeData = this.enrolleesDetails.representative;
          }
          if (Object.keys(this.fetchCaseManagerData || {}).length == 0) {
            this.fetchCaseManagerData = this.enrolleesDetails.caseManager;
          }
          if (Object.keys(this.fetchEmployerData || {}).length == 0) {
            this.fetchEmployerData = this.enrolleesDetails.employers;
          }
          if (Object.keys(this.fetchParticipantVendorInfoData || {}).length == 0) {
            this.fetchParticipantVendorInfoData = this.enrolleesDetails.vendorInfo;
          }

          let body = {
            type: 'PARTICIPANT',
            participant: {
              ...this.fetchParticipantData,
              middleName: this.fetchParticipantData.middleInitial,
              nickName: this.fetchParticipantData.altNameContact,
            },
            representative: {
              ...this.fetchRepresentativeData,
              address: {
                ...this.fetchRepresentativeData?.addressPrimary,
              },
            },
            caseManager: {
              ...this.fetchCaseManagerData,
              dtClientId: '2',
            },
            employer: {
              ...this.fetchEmployerData,
              ssn: this.fetchEmployerData?.socialSecurityNumber,
              address: {
                ...this.fetchEmployerData?.addressPrimary,
              },
            },
            vendorInfo: {
              ...this.fetchParticipantVendorInfoData
            }
          };

          enrollmentService.saveParticipantDetails(this.enrolleeId, body).then(
            (response) => {
              this.isLoading = false;
              this.getEnrollees(this.enrolleeId);
              edpService.processEnrolleeSavedResponse(response, this.$toast.bind(this));
            },
            (error) => {
              console.error(error);
              this.isLoading = false;
            },
          );
        } else if (this.participantType === 'Caregiver') {
          const caregiverDetails = {
              ...this.enrolleesDetails.caregiver.caregiverDetails,
              ...this.fetchEmployeeData,
          }

          if (Object.keys(this.fetchDemographicData || {}).length == 0) {
            if (this.enrolleesDetails.caregiver) {
              this.fetchDemographicData = this.enrolleesDetails.caregiver;
            }
          }
          if (this.fetchDemographicData.addressPrimary)
            this.fetchDemographicData.addressPrimary.state = this.fetchDemographicData.addressPrimary?.stateAbbrev || this.fetchDemographicData.addressPrimary?.state || null

          this.fetchCaseManagerData = this.enrolleesDetails.caseManager;

          const body = {
            type: 'CAREGIVER',
            caregiver: {
              ...this.fetchDemographicData,
              dateOfBirth: this.fetchDemographicData.dateOfBirth,
              middleName: this.fetchDemographicData.middleInitial,
              nickName: this.fetchDemographicData.altNameContact,
              // countryOfBirth: this.fetchDemographicData.dateOfBirth,
              dtClientId: '2',
              ...caregiverDetails
            },
            caseManager: {
              ...this.fetchCaseManagerData,
            },
          };

          enrollmentService.saveCaregiverDetails(this.enrolleeId, body).then(
            (response) => {
              this.isLoading = false;
              this.getEnrollees(this.enrolleeId);
              edpService.processEnrolleeSavedResponse(response, this.$toast.bind(this));
            },
            (error) => {
              console.error(error);
              this.isLoading = false;
            },
          );
        } else if (this.participantType === 'Vendor') {
          if (Object.keys(this.fetchDemographicData || {}).length == 0) {
            if (this.enrolleesDetails.vendor) {
              this.fetchDemographicData = this.enrolleesDetails.vendor;
            }
          }

          const body = {
            type: 'VENDOR',
            vendor: { ...this.fetchVendorData },
          };

          enrollmentService.saveVendorDetails(this.enrolleeId, body).then(
            (response) => {
              this.isLoading = false;
              this.getEnrollees(this.enrolleeId);
              edpService.processEnrolleeSavedResponse(response, this.$toast.bind(this));
            },
            (error) => {
              console.error(error);
              this.isLoading = false;
            },
          );
        }
      }
    },
    getParticipantData(data) {
      this.fetchParticipantData = data;
      this.enrolleesDetails.participant = data;
    },
    getEmployerData(data) {
      this.fetchEmployerData = data;
      this.enrolleesDetails.employers = data;

      if (data.ssn && data.ssn === this.fetchParticipantData.socialSecurityNumber && data.firstName === this.fetchParticipantData.firstName && data.lastName === this.fetchParticipantData.lastName) {
        this.participantIsEOR = true;
      }
    },
    participantAsEOR() {
      this.fetchEmployerData = Object.assign(
          this.fetchEmployerData,
          {
            ...this.fetchParticipantData,
            address: this.fetchParticipantData.addressPrimary,
            ssn: this.fetchParticipantData.socialSecurityNumber,
            relationship: 8
          }
      );
    },
    EORAsParticipant() {
      this.fetchParticipantData = Object.assign(
          this.fetchParticipantData,
          {
            ...this.fetchEmployerData
          }
      )
    },
    setEmployerAsParticipant(data) {
      this.participantIsEOR = data;
      if (data) {
        this.participantAsEOR();
      } else {
        this.fetchEmployerData = Object.assign(this.fetchEmployerData, this.originalEnrolleeDetails.employers)
      }
    },
    getRepresentativetData(data) {
      this.fetchRepresentativeData = data;
      this.enrolleesDetails.representative = data;
    },
    getCaseManagerData(data) {
      this.fetchCaseManagerData = data;
      this.enrolleesDetails.caseManager = data;
    },
    getParticipantVendorInfoData(data) {
      this.fetchParticipantVendorInfoData = data;
      this.enrolleesDetails.vendorInfo = data;
    },
    getDemographicData(data) {
      this.fetchDemographicData = data;
      this.enrolleesDetails.caregiver = data
    },
    getEmployeeData(data) {
      this.fetchEmployeeData = data;
      this.enrolleesDetails.caregiver.caregiverDetails = data
    },
    getVendorData(data) {
      this.fetchVendorData = data;
      this.enrolleesDetails.vendor = data;
    },
    getParticipantType(data) {
      this.participantType = data;
    },
    addNewCaregiver() {
      this.newCaregiver = true;
      this.participantChoice = 'caregiver';
      this.participantIndex = null;
      this.federalDocuments = '';
      this.stateDocuments = '';
      this.programDocuments = '';
      this.miscellaneousDocuments = '';
      this.imageCaptureDocuments = '';
      this.enrollmentTitle = '';
      this.enrolleesDetails.caregiver = this.defaultCaregiver;
      this.participantType = 'add_caregiver';
      this.showUnsavedChanges = false;
      this.changesToSave = [];
    },
    determineUrlParams() {
      const query = Object.assign({}, this.$route.query);
      if (query.tab === 'add_caregiver') {
        this.addNewCaregiver();
      } else if (!query.tab || query.tab === '') {
        this.updateUrlQuery(this.participantType);
      } else {
        this.participantType = query.tab.charAt(0).toUpperCase() + query.tab.slice(1);
      }
    },
    updateUrlQuery(value) {
      const query = `?tab=${value.toLowerCase()}`;
      history.pushState(
          {},
          null,
          this.$route.path + query
      )
    },
    getNotes() {
      let note = { enrollee: this.enrolleesParticipant.map(e => e.id).join(',') }
      enrollmentService.getAllNotes(note).then(
        (response) => {
          if (response) {
            this.notes = response.data.slice(0, 4);
          }
        },
        (error) => {
          console.error(error);
        },
      );
    },
    nullifyDocusignSelection(id) {
      this.isLoading = true
      docusignService.nullifyDocuSignForEnrollee(id).then((response) => {
        this.isLoading = false
        if (response.status === 201) {
          this.$toast(true, 'Updated Successfully');
        } else {
          this.$toast(false, 'Error', response.response?.data?.errors[0].message);
        }
      }, (error) => {
        console.error(error);
      })
    },
    getTypeById(value) {
      return Object.keys(this.participantTypes).find(key => this.participantTypes[key] === value)
    },
    returnEnrollmentToActive() {
      this.isLoading = true;
      enrollmentService.returnEnrollmentToActive(this.enrollmentDetails.id).then((response) => {
        this.enrollmentDetails.isArchived = false;
        this.isLoading = false;
        if (response.status === 200) {
          this.$toast(true, 'Updated Successfully');
        } else {
          this.$toast(false, 'Error', response.response?.data?.errors[0].message);
        }
      }, (error) => {
        console.error(error);
      })
    },
    selfServiceAlert(event) {
      event.target.checked = !event.target.checked;
      this.showSelfServiceModal = true;
    },
    setSelfServiceTrue() {
      this.dismissModal();
      this.showSpinner = true;
      if (this.participantType === 'Participant') this.fetchParticipantData.roleId = 5;
      if (this.participantType === 'Caregiver') this.fetchDemographicData.roleId = 5;
      if (this.participantType === 'Vendor') this.fetchVendorData.roleId = 5;
      this.saveEnrollees();
      if (this.participantType === 'Participant') this.updateEnrollmentSchedule(ENROLLMENTS_SCHEDULE_TYPES.SELF_SERVICE);
      this.showSpinner = false;
    },
    setSelfServiceFalse() {
      this.dismissModal();
      this.showSpinner = true;
      if (this.participantType === 'Participant') this.fetchParticipantData.roleId = null;
      if (this.participantType === 'Caregiver') this.fetchDemographicData.roleId = null;
      if (this.participantType === 'Vendor') this.fetchVendorData.roleId = null;
      this.saveEnrollees();
      if (this.participantType === 'Participant' && this.enrollmentDetails.scheduleId === 3) this.updateEnrollmentSchedule(ENROLLMENTS_SCHEDULE_TYPES.OPEN);
      this.showSpinner = false;
    },
    sendSelfServiceEmail() {
      enrollmentService.sendEmail({enrolleeId:this.enrolleeId})
        .then(() => {
          this.$toast(true, 'Email sent');
        })
        .catch((error) => {
          console.error(error)
          this.$toast(true, 'Error', 'Failed to send email');
        });
    },
    updateEnrollmentSchedule(schedule) {
      enrollmentService.updateStatusAndScheduleType({enrollmentId: this.enrollmentDetails.id, scheduleId: schedule});
    },
    completeEnrollment() {
      enrollmentService.updateStatusAndScheduleType({enrollmentId: this.enrollmentDetails.id, statusId: ENROLLMENT_STATUS_TYPES.COMPLETED});
    },
    removeNhepStatus() {
      this.isLoading = true;
      enrollmentService.removeNhepStatus(this.enrollmentDetails.id).then(() => {
        this.getEnrollmentDetails();
        this.isLoading = false;
      });
    },
    closeModal() {
      this.showUnsavedChanges = false;
    },
    unsavedChanges(data) {
      let changed = false
      if (this.participantChoice.includes('-')) {
        const choice = this.participantChoice.split('-');
        changed = this.originalEnrolleeDetails[choice[0]][choice[1]][data] !== this.enrolleesDetails[choice[0]][choice[1]][data];
      } else {
        changed = this.originalEnrolleeDetails[this.participantChoice][data] !== this.enrolleesDetails[this.participantChoice][data];
      }
      if (changed) {
        this.changesToSave.push(this.participantChoice + '_' + data)
      }
      else {
        this.changesToSave = this.changesToSave.filter(c => c === this.participantChoice + '_' + data)
      }
    },
    showUnsavedChangesModal() {
      this.showUnsavedChanges = this.changesToSave.length;
      if (!this.showUnsavedChanges) this.jumpToFunction();
    },
  },
  created() {
    this.getEnrolleesList();
  },
  computed: {
    ...mapStores(useScheduleStore),
    enrollmentId() {
      return this.enrolleesParticipant.map((enrollee) => {
        this.enrollment.id = enrollee.id;
        return this.enrollment.id;
      });
    },
    isSelfService() {
      switch(this.participantType) {
        case 'Participant': return (this.fetchParticipantData.roleId === 5);
        case 'Caregiver': return (this.fetchDemographicData.roleId === 5);
        case 'Vendor': return (this.fetchVendorData.roleId === 5);
        default: return false;
      }
    },
    canSendSelfService() {
      return (this.isSelfService && this.enrollmentDetails.statusId !== ENROLLMENT_STATUS_TYPES.CONFIGURATION_NEEDED);
    },
  },
  watch: {
    participantType: function(value) {
      if (value) {
        this.updateUrlQuery(value);
      }
    },
    fetchParticipantData: {
      handler() {
        if (this.participantIsEOR) this.participantAsEOR();
      },
      deep: true
    },
    fetchEmployerData: {
      handler() {
        if (this.participantIsEOR) this.EORAsParticipant();
      },
      deep: true
    },
  }
};
</script>
